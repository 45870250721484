import { Button, Form, Input, Modal, Select, Upload, message } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";

dayjs.extend(customParseFormat);

const EditAmenities = ({ setModalOpen, modalOpen, data, refetch }) => {
    const [form] = Form.useForm();
    const user = JSON.parse(localStorage.getItem("user"));
    const [loading, setLoading] = useState(false);
    const [imageList, setImageList] = useState([]);

    // Handle image upload changes
    const handleImagesChange = useCallback(
        (newImages) => {
            if (newImages.length > 1) {
                message.error("You can only upload one icon!");
                return;
            }
            setImageList(newImages);
            form.setFieldsValue({
                icon: newImages.length > 0 ? newImages[0].link : undefined,
            });
        },
        [form]
    );

    // Populate form with initial data
    useEffect(() => {
        console.log("data.name.english", data)
        if (data) {
            form.setFieldsValue({
                name: data.name.english,
                nameRU: data.name.russian,
                nameAR: data.name.arabic,
                active: data.active,
                description: data.description,
                icon: data.icon,
            });
            setImageList(
                data.icon
                    ? [
                        {
                            link: data.icon,
                            fileName: data.icon.split("/").pop(),
                        },
                    ]
                    : []
            );
        }
    }, [data, form]);

    const handleCancel = () => {
        setModalOpen(false);
    };

    const onFinish = async (values) => {
        // Prepare the payload
        const payload = {
            name: {
                english: values.name,
                arabic: values.nameAR,
                russian: values.nameRU,
            },
            active: values.active,
        };

        // Handle the icon field
        if (imageList.length === 1) {
            payload.icon = imageList[0].link; // Use uploaded image
        } else if (!data.icon) {
            // If no existing icon and no image uploaded, show error
            message.error("Please upload an icon.");
            return;
        } else {
            payload.icon = data.icon; // Retain the existing icon if no image is uploaded
        }

        setLoading(true);
        try {
            const response = await axios.patch(
                `${import.meta.env.VITE_APP_BASE_API_URL}/hotelAmenities?id=${data?.id}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.accessToken}`,
                    },
                }
            );
            message.success("Amenity updated successfully!");
            refetch();
            setModalOpen(false);
        } catch (error) {
            console.error("Error updating amenity:", error);
            message.error("Failed to update amenity.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <Modal
            okText="Save"
            onOk={() => {
                form
                    .validateFields()
                    .then(onFinish)
                    .catch((info) => console.log("Validate Failed:", info));
            }}
            okButtonProps={{ disabled: loading }}
            onCancel={handleCancel}
            open={modalOpen}
        >
            <Form
                form={form}
                name="edit-amenity"
                layout="vertical"
            >
                <Form.Item
                    name="icon"
                    label="Icon"
                    rules={[
                        {
                            validator: () =>
                                imageList.length === 1
                                    ? Promise.resolve()
                                    : Promise.reject(new Error("Please upload exactly one icon.")),
                        },
                    ]}
                >
                    <UploadMultipleImagesHotel data={imageList} onChange={handleImagesChange} />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Please input the name!" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Name in Russian"
                    name="nameRU"
                    rules={[{ required: true, message: "Please input the name in Russian!" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Name in Arabic"
                    name="nameAR"
                    rules={[{ required: true, message: "Please input the name in Arabic!" }]}
                >
                    <Input dir="rtl" />
                </Form.Item>


                <Form.Item
                    name="active"
                    label="Active"
                    rules={[{ required: true, message: "Please select the active state!" }]}
                >
                    <Select>
                        <Select.Option value={true}>True</Select.Option>
                        <Select.Option value={false}>False</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditAmenities;

