function RoomTypesIcon() {
    return (
        <span className="anticon ant-menu-item-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="white"
            >
                <path d="M2 10V3h20v7h-2V5H4v5H2zm0 1h20v2h1v3h-2v-1H3v1H1v-3h1v-2zm2-4h6V5H4v2zm8 0h8V5h-8v2z" />
            </svg>

        </span>



    );
}

export default RoomTypesIcon;
