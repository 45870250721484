import { Button, Table, Input, DatePicker } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ViewOfferModal from "./ViewOfferModal";
import AddOfferModal from "./AddOfferModal";
import Swal from "sweetalert2";
import DOMPurify from 'dompurify';
import DateFilterDropdown from "../OrdersListTab/DateFilterDropdown";
import dayjs from "dayjs";

// render description html
const renderHTML = (htmlString) => {
  const cleanHTML = DOMPurify.sanitize(htmlString);
  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};

const OffersListTab = () => {
  const today = dayjs().startOf("day");
  const auth = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [validUntil, setvalidUntil] = useState();
  const [validFrom, setvalidFrom] = useState();
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [dateFilter, setDateFilter] = useState([dayjs()]);
  const [filteredData, setFilteredData] = useState([]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {}, // Add filters for searching
  });
  const applyDateFilter = () => {
    if (!dateFilter || dateFilter.length !== 2) return data;

    const [start, end] = dateFilter;
    return data.filter((item) => {
      const validUntil = dayjs(item.validUntil);
      return validUntil.isAfter(start, "day") && validUntil.isBefore(end, "day");
    });
  };
  useEffect(() => {
    setFilteredData(applyDateFilter());
  }, [data, dateFilter]);

  const columns = [
    {
      title: "Order",
      dataIndex: "order",

    },
    {
      title: "Title",
      dataIndex: ["title", "english"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search title"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8, textAlign: 'right' }}>
            <Button size="small" style={{ marginRight: 8 }} onClick={() => {
              clearFilters();
              confirm();
            }}>Reset</Button>
            <Button size="small" type="primary" onClick={() => confirm()}>Search</Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.title.english.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Description",
      dataIndex: ["description", "english"],
      width: "25%",
      render: (htmlContent) => renderHTML(htmlContent),
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      render: (val, row) => val + row.percentageSign,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search percentage"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8, textAlign: 'right' }}>
            <Button size="small" style={{ marginRight: 8 }} onClick={() => {
              clearFilters();
              confirm();
            }}>Reset</Button>
            <Button size="small" type="primary" onClick={() => confirm()}>Search</Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.percentage.toString().includes(value),
    },
    {
      title: "Start Date",
      dataIndex: "validFrom",
      render: (text) => new Date(text).toLocaleDateString(),
      filterDropdown: () => (
        <DatePicker
          onChange={(date) => handleStartdate(date)}
          onClear={() => handleStartdate(null)} // Reset on clear
          allowClear
          style={{ marginRight: 16 }}
        />
      ),
      onFilter: (value, record) => {
        return dayjs(record.validFrom).isSame(value, "day");
      },
    },

    {
      title: "End Date",
      dataIndex: "validUntil",
      render: (text) => new Date(text).toLocaleDateString(),
      filterDropdown: () => (
        <DatePicker
          defaultValue={today}
          onChange={(date) => handleEnddate(date)}
          onClear={() => handleEnddate(null)} // Reset on clear
          allowClear
          style={{ marginRight: 16 }}
        />
      ),
      onFilter: (value, record) => {
        return dayjs(record.validUntil).isSame(value, "day");
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, row,index) => (
        <div className="row-actions">
          <Button
            style={{ marginRight: 16 }}
            onClick={() => handleTopClick(row)}
            disabled={(row.order == 1 || index == 0)}
          >
            TOP
          </Button>
          <Button
            style={{ marginRight: 16 }}
            onClick={() => handleUpClick(row)}
            disabled={row.order == 1|| index == 0}
          >
            Up
          </Button>
          <Button
            style={{ marginRight: 16 }}
            onClick={() => handleDownClick(row)}
            disabled={index == data.length - 1}
          >
            Down
          </Button>
          <Button
            style={{ marginRight: 16 }}
            onClick={() => handleViewClick(row)}
          >
            View
          </Button>
          <Button danger onClick={() => handleDeleteClick(row)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    const { current, pageSize } = tableParams.pagination;

    const today = dayjs().startOf("day").format("YYYY-MM-DD");

    const apiUrl = `${import.meta.env.VITE_APP_BASE_API_URL}/offers/get-offers`;
    const params = {
      hotel: selectedHotel.hotelID,
      page: current,
      pageSize: pageSize,
      validUntil: today, // Pass tomorrow's date
      ...tableParams.filters, // Include filters in the request
    };


    axios
      .get(apiUrl, {
        params: params,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.offers);
        setData(response.data.offers);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.totalCount,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
        setLoading(false);
      });
  };



  const handleStartdate = (date) => {
    const { current, pageSize } = tableParams.pagination;
    const today = dayjs().startOf("day").format("YYYY-MM-DD");

    setLoading(true);

    const apiUrl = `${import.meta.env.VITE_APP_BASE_API_URL}/offers/get-offers`;

    const params = {
      hotel: selectedHotel.hotelID,
      page: current,
      pageSize: pageSize,
      validFrom: date ? dayjs(date).startOf("day").format("YYYY-MM-DD") : validFrom, // Use the new date if provided, otherwise keep the existing validFrom
      validUntil: validUntil || today, // Use validUntil or default to today
      ...tableParams.filters,
    };


    axios
      .get(apiUrl, {
        params: params,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setData(response.data.offers);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.totalCount,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
        setLoading(false);
      });
  };

  const handleEnddate = (date) => {
    const { current, pageSize } = tableParams.pagination;
    const today = dayjs().startOf("day").format("YYYY-MM-DD");

    setLoading(true);

    const apiUrl = `${import.meta.env.VITE_APP_BASE_API_URL}/offers/get-offers`;

    const params = {
      hotel: selectedHotel.hotelID,
      page: current,
      pageSize: pageSize,
      validFrom: validFrom ? dayjs(validFrom).startOf("day").format("YYYY-MM-DD") : undefined, // Send validFrom only if set
      validUntil: date ? dayjs(date).endOf("day").format("YYYY-MM-DD") : validUntil, // If the end date is not selected, keep validUntil as is
      ...tableParams.filters,
    };


    axios
      .get(apiUrl, {
        params: params,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setData(response.data.offers);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.totalCount,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
        setLoading(false);
      });
  };

  const handleViewClick = (record) => {
    setModalData(record);
    setViewModalOpen(true);
  };

  const handleDeleteClick = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Delete : ", auth.data.accessToken);
        axios
          .delete(`${import.meta.env.VITE_APP_BASE_API_URL}/offers`, {
            params: { id: record.id },
            headers: {
              Authorization: `Bearer ${auth.data.accessToken}`,
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "Your offer has been deleted.", "success");
            fetchData(); // Refresh data after successful deletion
          })
          .catch((error) => {
            Swal.fire("Error!", "There was a problem deleting the offer.", "error");
            console.error("Error deleting offer:", error);
          });
      }
    });
  };
  const handleTopClick = async (record) => {
    await axios
      .patch(`${import.meta.env.VITE_APP_BASE_API_URL}/offers/move-to-top?hotel=${selectedHotel.hotelID}&id=${record.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        })
      .then(() => {
        Swal.fire("Moved!", "Your offer has been Moved.", "success");
        fetchData(); // Refresh data after successful deletion
      })
      .catch((error) => {
        Swal.fire("Error!", "There was a problem move the offer.", "error");
        console.error("Error Moved offer:", error);
      });
  };
  const handleUpClick = async (record) => {
    await axios
      .patch(`${import.meta.env.VITE_APP_BASE_API_URL}/offers/move-up?hotel=${selectedHotel.hotelID}&id=${record.id}`,
        null, {
        headers: {
          Authorization: `Bearer ${auth.data.accessToken}`,
        },
      })
      .then(() => {
        Swal.fire("Moved!", "Your offer has been Moved.", "success");
        fetchData(); // Refresh data after successful deletion
      })
      .catch((error) => {
        Swal.fire("Error!", "There was a problem move the offer.", "error");
        console.error("Error Moved offer:", error);
      });
  };
  const handleDownClick = async (record) => {
    await axios
      .patch(`${import.meta.env.VITE_APP_BASE_API_URL}/offers/move-down?hotel=${selectedHotel.hotelID}&id=${record.id}`,
        null, {
        headers: {
          Authorization: `Bearer ${auth.data.accessToken}`,
        },
      })
      .then(() => {
        Swal.fire("Moved!", "Your offer has been Moved.", "success");
        fetchData(); // Refresh data after successful deletion
      })
      .catch((error) => {
        Swal.fire("Error!", "There was a problem move the offer.", "error");
        console.error("Error Moved offer:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, selectedHotel.hotelID, tableParams.filters]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
        <Button
          type="primary"
          onClick={() => setAddModalOpen(true)}
          style={{ marginBottom: 16 }}
        >
          Add Offer
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={tableParams.pagination}
        loading={loading}
        onChange={(pagination, filters) => setTableParams({ ...tableParams, pagination, filters })}
      />


      {/* Modal for Viewing Offer */}
      {viewModalOpen && (
        <ViewOfferModal
          modalOpen={viewModalOpen}
          setModalOpen={setViewModalOpen}
          data={modalData}
          refetch={fetchData}
        />
      )}

      {/* Modal for Adding Offer */}
      {addModalOpen && (
        <AddOfferModal
          refetch={fetchData}
          hotel={selectedHotel.hotelID}
          addModalOpen={addModalOpen}
          setAddModalOpen={() => setAddModalOpen(false)}
          onAddOffer={() => {
            fetchData();
            setAddModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default OffersListTab;
