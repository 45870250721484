import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import ViewLoyalityTier from "./ViewLoyalityTier";
import CreateLoyalityTier from "./CreateLoyalityTier";
import "../../../assets/scss/General.scss";

const LoyalityTiers = () => {
  const [tiers, setTiers] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const columns = [
    { title: "ID", dataIndex: "id" },
    { title: "Tier Name", dataIndex: ["name" , "english"] },
    { title: "Minimum Points", dataIndex: "minPoints" },
    { title: "Maximum Points", dataIndex: "maxPoints" },
    { title: "Actions", render: (_, row) => <ViewLoyalityTier data={row} /> },
  ];
  const fetchTiers = () => {
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/loyaltyTiers/get-tiers-v2`,

        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.usersLoyaltyTiers);
        setTiers(res?.data?.usersLoyaltyTiers);
        console.log(tiers);
      });
  };
  useEffect(() => {
    fetchTiers();
  }, []);

  return (
    <div>
      <div className="top-section">
        <div className="exchange">
          <b>Current Exchange Rate</b>
          <p>Earn 1 USD = 1 point </p>
          <p> Redeem 100 point = 5 USD</p>
        </div>
        {[1].includes(user.roles.id) ? (
          <div className="table-header">
            <Button onClick={() => setCreateModal(true)}>Add</Button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Table
        rowKey={(record) => record.id}
        dataSource={tiers}
        pagination={false}
        columns={columns}
      />
      <CreateLoyalityTier
        createModal={createModal}
        setCreateModal={setCreateModal}
        refetch={fetchTiers}
      />
    </div>
  );
};

export default LoyalityTiers;
