import { Button, Result, Spin, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import AddServiceCategory from "../../components/AddServiceCategory";
import DeleteServiceCategory from "../../components/DeleteServiceCategory";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EditServiceCategory from "../../components/EditServiceCategory";

const Maintenance = () => {
  // Get HOTEL SERVICES from store
  const hotelServices = useSelector(
    (state) => state.hotelReducer?.hotel?.services
  );
  // Find THIS service from the fetched services
  const hotelService = hotelServices.find(
    (service) => service?.name === "Maintenance"
  );

  const hotelLoading = useSelector((state) => state.hotelReducer.loadingHotel);

  const [data, setData] = useState([]);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchService = () => {
    setLoading(true);

    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL
        }/serviceCategories/get-categories?service=${hotelService?.id ? hotelService?.id : 30
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setData(res.data.serviceCategories);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["name", "english"],
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Link to={`${record.id}`}>View</Link>
          {[1,12 ,14].includes(user?.roles?.id) && (
            <EditServiceCategory categ={record} refetch={fetchService} />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchService();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelServices]);

  return (
    <>
      {[1, 12,14].includes(user?.roles?.id) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
          className="top-controls"
        >
          <Button
            onClick={() => setAddCategoryModal(true)}
            type="primary"
            style={{ marginRight: 16, textAlign: "right" }}
          >
            Add Category
          </Button>
          {[12,14].includes(user?.roles?.id) ? null :
            <Button
              type="dashed"
              danger
              onClick={() => setDeleteCategoryModal(true)}
            >
              Delete Category
            </Button>
          }
        </div>
      ) : (
        ""
      )}
      {loading || hotelLoading ? (
        <Spin
          style={{ position: "absolute", left: "50%", top: "50%" }}
          size="large"
        />
      ) : data?.length === 0 ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, there's no available categories, please contact your adminstrator"
        />
      ) : (
        <Table
          rowKey={(record) => record.id}
          pagination={false}
          columns={columns}
          dataSource={data}
        />
      )}

      {/* NOTE:  lama teegi t3ml add category pass serviceID */}
      <AddServiceCategory
        setAddCategoryModal={setAddCategoryModal}
        addCategoryModal={addCategoryModal}
        refetch={fetchService}
        serviceID={hotelService?.id}
      />
      <DeleteServiceCategory
        refetch={fetchService}
        setDeleteCategoryModal={setDeleteCategoryModal}
        deleteCategoryModal={deleteCategoryModal}
        categories={data}
      />
    </>
  );
};

export default Maintenance;
