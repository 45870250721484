import CheckInOutChart from "./CheckInOutChart";
import AvailableRoomsChart from "./AvailableRoomsChart";
import ServiceCountersChart from "./ServiceCountersChart";
import MaintenanceSoloChart from "./MaintenanceSoloChart";
import HousekeepingSoloChart from "./HousekeepingSoloChart";
import RoomServiceSoloChart from "./RoomServiceSoloChart";
import FrontOfficeSoloChart from "./FrontOfficeSoloChart";

import LoyalityTiersChart from "./LoyalityTiersChart";
import HouseKeeping from "../../assets/housekeeping.svg";
import RoomService from "../../assets/room-service.svg";
import Maintenance from "../../assets/maintenance.svg";
import FrontOffice from "../../assets/frontOffice.svg";

import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Spin } from "antd";

const checkingPerms = [1, 12, 2, 3, 4];
const mnPerms = [1, 12, 2, 3, 4, 5, 14];
const hkPerms = [1, 12, 2, 3, 4, 7, 14];
const rsPerms = [1, 12, 2, 3, 4, 9, 14];
// const checkingPerms = [1, 12, 2, 3, 4, ];

const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [chartData, setChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const fetchChart = () => {
    setChartLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/statistics/services-orders?hotel=${
          selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
        }&startDate=${dayjs().format("YYYY-MM-DD")}&endDate=${dayjs().format(
          "YYYY-MM-DD"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        let temp = [
          {
            name: "Room Service",
            value: res.data.roomServiceOrders,
          },
          { name: "Maintenance", value: res.data.maintenanceServiceOrders },
          { name: "Housekeeping", value: res.data.housekeepingOrders },
          { name: "Front Office", value: res.data.frontOfficeServiceOrders },
        ];
        setChartData(temp);
        setChartLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchChart();
  }, [selectedHotel.hotelID]);

  return (
    <div className="dashboard-container">
      <div className="top-stats">
        <div className="box-1">
          <img src={RoomService} className="icon" />
          <div className="content">
            {chartLoading ? (
              <Spin />
            ) : (
              <h4>
                {chartData[0]?.value} <p className="inner-title">Orders</p>
              </h4>
            )}

            <h3>Room Service</h3>
          </div>
        </div>
        <div className="box-2">
          <img src={Maintenance} className="icon" />
          <div className="content">
            {chartLoading ? (
              <Spin />
            ) : (
              <h4>
                {chartData[1]?.value} <p className="inner-title">Orders</p>
              </h4>
            )}

            <h3>Maintenance</h3>
          </div>
        </div>
        <div className="box-3">
          <img src={HouseKeeping} className="icon" />
          <div className="content">
            {chartLoading ? (
              <Spin />
            ) : (
              <h4>
                {chartData[2]?.value} <p className="inner-title">Orders</p>
              </h4>
            )}

            <h3>Housekeeping</h3>
          </div>
        </div>
        <div className="box-4">
          <img src={FrontOffice} className="icon" />
          <div className="content">
            {chartLoading ? (
              <Spin />
            ) : (
              <h4>
                {chartData[3]?.value} <p className="inner-title">Orders</p>
              </h4>
            )}

            <h3>Front Office</h3>
          </div>
        </div>
      </div>
      <div className="charts">
        {checkingPerms.includes(user?.roles?.id) && <CheckInOutChart />}
        {checkingPerms.includes(user?.roles?.id) && <AvailableRoomsChart />}
        {checkingPerms.includes(user?.roles?.id) && <LoyalityTiersChart />}
        {checkingPerms.includes(user?.roles?.id) && <ServiceCountersChart />}
        {mnPerms.includes(user?.roles?.id) && <MaintenanceSoloChart />}
        {hkPerms.includes(user?.roles?.id) && <HousekeepingSoloChart />}
        {rsPerms.includes(user?.roles?.id) && <RoomServiceSoloChart />}
        {rsPerms.includes(user?.roles?.id) && <FrontOfficeSoloChart />}
      </div>
    </div>
  );
};

export default Dashboard;
