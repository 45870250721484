import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotel: {
    hotelName: "",
    hotelID: null,
    services: [],
  },
  loadingHotel: false,
};

export const hotelSlice = createSlice({
  name: "hotelList",
  initialState,
  reducers: {
    setHotel: (state, action) => {
      state.hotel.hotelName = action.payload.hotelName;
      state.hotel.hotelID = action.payload.hotelID;
      state.hotel.services = action.payload.services || []; // Avoid overwriting services
      state.loadingHotel = false;
    },
    setHotelLoading: (state, action) => {
      state.loadingHotel = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHotel, setHotelLoading } = hotelSlice.actions;

export default hotelSlice.reducer;
