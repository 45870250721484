import React, { useState, useEffect } from "react";

const CustomSelect = ({ options, onSearch, value, onChange, placeholder }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Filter options based on the search input
  useEffect(() => {
    if (value.length >= 3) {
      setLoading(true);
      setFilteredOptions(
        options.filter((option) =>
          option.label.toLowerCase().includes(value.toLowerCase())
        )
      );
      setLoading(false);
    } else {
      setFilteredOptions([]);
    }
  }, [value, options]);

  // Handles the input change
  const handleInputChange = (e) => {
    onChange(e.target.value); // Update the search input state in the parent component
  };

  // Handles when the user selects an option
  const handleSelectOption = (option) => {
    onChange(option.label); // Set the input value to the selected option
    onSearch(option.value); // Trigger the search callback with the selected value
    setFilteredOptions((prevOptions) =>
      prevOptions.filter((item) => item.value !== option.value) // Remove the selected option
    );
  };

  // Trigger the search when Enter is pressed
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && value.length >= 3) {
      onSearch(value); // Trigger search when Enter is pressed
    }
  };

  return (
    <div style={{ width: "70%", position: "relative", display: "flex", gap: "8px", alignItems: "center", justifyContent: "space-between" }}>
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} // Listen for Enter key
        placeholder={placeholder || "Search..."}
        style={{
          width: "100%",
          padding: "8px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          outline:"none"

        }}
      />

      {/* Dropdown List */}
      {filteredOptions.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "35px",
            left: "0",
            right: "0",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: "1000",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            outline:"none"
          }}
        >
          {loading ? (
            <div style={{ padding: "8px", textAlign: "center" }}>Loading...</div>
          ) : (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleSelectOption(option)} // Handle option click
              >
                {option.label}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
