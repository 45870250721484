import { Button, DatePicker, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import CustomSelect from "../../components/CustomSelect";
import CustomSearchSelect from "../../components/CustomSearchSelect";

const AddNotificationModal = ({ setAddModalOpen, addModalOpen, hotel }) => {
  const [loadingHotels, setLoadingHotels] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [notificationTarget, setNotificationTarget] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchInputList, setSearchInputList] = useState("");
  const [usersOptions, setUsersOptions] = useState([]);
  const [Userslist, setUserslist] = useState([]);
  const [hotelOptions, setHotelOptions] = useState([]);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedUsers, setSelectedUsers] = useState([]);

  const onFinish = async (values) => {
    console.log("Success:", values);
    const payload = {
      description: {
        english: values.description,
        russian: values.descriptionRU,
        arabic: values.descriptionAR,
      },
      // NOTE: target [users, user:userID, hotel: hotelID]
      repeatInterval: values.repeatInterval,
      sendDate: values.sendDate,
      title: {
        english: values.title,
        russian: values.titleRU,
        arabic: values.titleAR,
      },
      target: values.target,
      active: true,  // Set 'active' as a boolean instead of 1
      repeat: true,  // Set 'repeat' as boolean if this is the intention
      type: "mobile",
    }

    if (values.target == "user") {
      const targetUser = usersOptions.find((option) => option.label === searchInput);
      if (targetUser) {
        payload.userId = targetUser.value
      }
    }
    if (values.target == "hotel") {
      payload.hotel = values.hotel
    }
    if (values.target == "users") {
      const users = searchInputList.map((user) => user.value);
      payload.usersIDs = users
    }
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/notifications`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )

      .then(function (response) {
        console.log(payload)
        console.log(response);
        form.resetFields();
        setNotificationTarget("");
        setAddModalOpen(false);
        setSearchInput("")
        setSearchInputList("")
        setUsersOptions([])
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  const handleFormValueChange = (val) => {
    if (val.target) {
      setNotificationTarget(val.target);
      if (val.target === "hotel") {
        if (hotelOptions.length === 0) {
          setLoadingHotels(true);
          axios
            .get(`${import.meta.env.VITE_APP_BASE_API_URL}/hotels/get-hotels`, {
              headers: {
                Authorization: `Bearer ${auth.data.accessToken}`,
              },
            })
            .then((res) => {
              setHotelOptions(
                res.data.hotels.map((hotel) => ({
                  value: hotel.id,
                  label: hotel.name.english,
                }))
              );
              setLoadingHotels(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      // } else if (val.target === "user") {
      //   if (usersOptions.length === 0) {
      //     setLoadingUsers(true);
      //     axios
      //       .get(
      //         `${import.meta.env.VITE_APP_BASE_API_URL
      //         }/users/get-users?role[]=${11}`,
      //         {
      //           headers: {
      //             Authorization: `Bearer ${auth.data.accessToken}`,
      //           },
      //         }
      //       )
      //       .then((res) => {
      //         console.log(res);
      //         // setUsersOptions(
      //         //   res.data.users.map((user) => ({
      //         //     value: user.id,
      //         //     label: user.email,
      //         //   }))
      //         // );
      //         setUserslist(
      //           res.data.users.map((user) => (user.id))
      //         );
      //         setLoadingUsers(false);
      //       });
      //   }
      }
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setNotificationTarget("");
    setSearchInputList("")
    setAddModalOpen(false);
    setUsersOptions([])
    setSearchInput("")
  };
  const filterOption = (input, option) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const handleSearch = async (search) => {
    if (search.length >= 3) {
      setLoadingUsers(true); // Show spinner
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_BASE_API_URL}/users/get-users-V2?email=${search}`,
          {
            headers: {
              Authorization: `Bearer ${auth.data.accessToken}`,
            },
          }
        );
        const newOptions = response.data.users.map((user) => ({
          value: user.id,
          label: user.email,
        }));
        setUsersOptions(newOptions); // Set users options for dropdown
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoadingUsers(false); // Hide spinner
      }
    }
  };
  const handleSearchList = (newOptions) => {
    setUsersOptions(newOptions); // Update options when search is completed
  };

  const handleSelectChange = (selectedIds) => {
    setSelectedUsers(selectedIds); // Update the selected users' IDs
  };
  return (
    <Modal
      destroyOnClose
      okText={"Create Notification"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addModalOpen}
    >
      <Form
        onValuesChange={handleFormValueChange}
        form={form}
        layout="vertical"
        name="add-notification"
      >
        <Form.Item
          name="title"
          label="Notification Title"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="titleRU"
          label="Notification Title in Russian"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="titleAR"
          label="Notification Title in Arabic"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item
          name="target"
          label="Notification Target"
          rules={[
            {
              required: true,
              message: "Please input a target!",
            },
          ]}
        >
          <Select onChange={(value) => setNotificationTarget(value)}>
            <Select.Option value="users">Users</Select.Option>
            <Select.Option value="user">User</Select.Option>
            <Select.Option value="hotel">Hotel</Select.Option>
            <Select.Option value="all">All</Select.Option>
          </Select>
        </Form.Item>

        {/* Conditional Rendering for User Target */}
        {notificationTarget === "user" ? (
          <Form.Item name="targetUser" label="Target User">
            <div style={{ display: "flex", gap: "8px", alignItems: "center", justifyContent: "space-between" }}>
              {/* Custom Select Field with Search */}
              <CustomSelect
                options={usersOptions}
                value={searchInput}
                onSearch={handleSearch}
                onChange={setSearchInput}
                placeholder="Search options"
              />
              {/* Search Button */}
              <Button
                type="primary"
                onClick={() => handleSearch(searchInput)} // Trigger search manually
                disabled={searchInput.length < 5} // Disable if input is too short
                loading={loadingUsers} // Show loading spinner while searching
              >
                Search
              </Button>
            </div>
          </Form.Item>
        ) : null}

        {notificationTarget === "users" ? (
          <Form.Item name="targetUser" label="Target User">
            <div style={{ display: "flex", gap: "8px", flexDirection: "column", rowGap: "30px" }}>
              <CustomSearchSelect
                options={usersOptions}
                value={searchInputList}
                onSearch={handleSearch}
                onChange={(value) => setSearchInputList(value)}
                placeholder="Search options"
              />
              <button
                type="button"
                onClick={() => handleSearch(searchInputList)} // Pass the correct search input
                disabled={searchInputList.length < 5} // Disable if input is too short
                style={{
                  padding: "8px 16px",
                  backgroundColor: searchInputList.length < 5 ? "rgba(0, 0, 0, 0.04)" : "#0d375c",
                  color: searchInputList.length < 5 ? "rgba(0, 0, 0, 0.25)" : "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: searchInputList.length < 5 ? "not-allowed" : "pointer",
                }}
              >
                {loadingUsers ? "Searching..." : "Search"}
              </button>
            </div>
          </Form.Item>
        ) : null}


        {notificationTarget === "hotel" ? (
          <Form.Item name="hotel" label="Target Hotel">
            {loadingHotels ? (
              <Spin />
            ) : (
              <Select options={hotelOptions} />
            )}
          </Form.Item>
        ) : null}

        <Form.Item
          name="description"
          label="Notification Description"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          {/* <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="descriptionRU"
          label="Notification Description in Russian"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          {/* <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="descriptionAR"
          label="Notification Description in Arabic"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          {/* <Input.TextArea dir="rtl" autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="repeatInterval"
          label="Repeat Interval"
          rules={[
            {
              type: "number",
              required: true,
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} controls={false} />
        </Form.Item>
        <Form.Item
          label="Send Date"
          name="sendDate"
          rules={[
            {
              required: true,
              message: "Please input a Send date!",
            },
          ]}
          getValueFromEvent={(onChange) =>
            dayjs(onChange).format("YYYY-MM-DD HH:mm")
          }
          getValueProps={(i) => dayjs(i)}
        >
          <DatePicker
            showTime={{ format: "HH:mm" }}
            allowClear={false}
            format="YYYY-MM-DD HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNotificationModal;
