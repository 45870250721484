import { UploadOutlined } from "@ant-design/icons";
import {
    Button,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    Upload,
} from "antd";
import axios from "axios";
import dayjs from 'dayjs';
import { useState } from "react";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";



const AddAmenities = ({ setAddModalOpen, addModalOpen, hotel, refetch }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [hereImage, setHereImage] = useState(false);
    const filterImages = (images) => {
        return images.map(({ fileName, link }) => ({ fileName, link }));
    };



    const onFinish = async (values) => {
        console.log("value :" , values);
        // Ensure the images are filtered correctly
        const filteredImages = filterImages(values.image);


        const payload = {
            name: {
                english: values.name,
                arabic: values.nameAR,
                russian: values.nameRU,
            },
            hotel: hotel,
            icon: filteredImages[0].link,
            active: values.active,
        };
        console.log("payload :" , payload);

        // Set loading state before making the API call
        setLoading(true);

        try {
            // Make the API call to submit the offer
            await axios.post(
                `${import.meta.env.VITE_APP_BASE_API_URL}/hotelAmenities`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${user.data.accessToken}`, // Send token for authorization
                    },
                }
            );

            // Refetch data and reset the form
            refetch();
            form.resetFields();
            setAddModalOpen(false);
        } catch (error) {
            // Handle errors if the API call fails
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response?.data?.message || "Something went wrong",
            });
        } finally {
            // Set loading state back to false after the API call
            setLoading(false);
        }
    };








    const handleCancel = () => {
        form.resetFields();
        setAddModalOpen(false);
    };

    return (
        <Modal
            okText={"Add Amenities"}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onFinish(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
            onCancel={handleCancel}
            confirmLoading={loading}
            open={addModalOpen}
        >
            <Form form={form} layout="vertical" name="addAmenitiesModal">
                <Form.Item
                    name="image"
                    label="Photo"
                    rules={[
                        {
                            required: true,
                            message: "Please upload at least one image",
                        },

                    ]}
                >
                    <UploadMultipleImagesHotel />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Name "
                    rules={[
                        {
                            required: true,
                            message: "Please input a title!",
                        },
                        {
                            whitespace: true,
                            message: "Please input a valid title!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="nameRU"
                    label="Name in Russian"
                    rules={[
                        {
                            required: true,
                            message: "Please input a title!",
                        },
                        {
                            whitespace: true,
                            message: "Please input a valid title!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="nameAR"
                    label="Name in Arabic"
                    rules={[
                        {
                            required: true,
                            message: "Please input a title!",
                        },
                        {
                            whitespace: true,
                            message: "Please input a valid title!",
                        },
                    ]}
                >
                    <Input dir="rtl" />
                </Form.Item>

                <Form.Item
                    name="active"
                    label="Active"
                    rules={[
                        {
                            required: true,
                            message: "Please select VIP State!",
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value={true}>True</Select.Option>
                        <Select.Option value={false}>False</Select.Option>
                    </Select>
                </Form.Item>




            </Form>
        </Modal>
    );
};

export default AddAmenities;
