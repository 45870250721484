import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Upload,
  Select,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useState, useEffect, useCallback } from "react";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";
import UploadProfilePicture from "../../components/UploadProfilePicture";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const ViewRoomModal = ({ setModalOpen, modalOpen, data, refetch, hotel }) => {
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [image, setimage] = useState("");
  const [imageIcon, setimageIcon] = useState("");
  const [ExtraFields, setExtraFields] = useState([]);
  const [showModal, setShowModal] = useState(false); // Manage modal visibility with state
  const [imageList, setImageList] = useState([]);

  ///Amenities

  //from api Amenities Active = true
  const [FetchAmenities, setFetchAmenities] = useState([]);
  //selected Id only from data
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  //filtered amenities for displaying selected ones  and searching  in the dropdown  list
  const [filteredAmenities, setFilteredAmenities] = useState(
    data?.amenities?.filter((amenity) => amenity.deletedBy !== 0)
  );

  const handleAmenityChange = (checkedValues) => {
    setSelectedAmenities(checkedValues);
  };
  const handleSelectAmenity = (selectedIds) => {
    setSelectedAmenities(selectedIds); // Update selected IDs

    // Find the full details of the selected amenities
    const selectedAmenitiesList = FetchAmenities.filter((amenity) =>
      selectedIds.includes(amenity.id)
    );
    setFilteredAmenities(selectedAmenitiesList); // Update displayed list
  };

  const handleRemoveAmenity = (id) => {
    const updatedIds = selectedAmenities.filter(
      (amenityId) => amenityId !== id
    );
    setSelectedAmenities(updatedIds); // Update selected IDs

    const updatedAmenities = filteredAmenities.filter(
      (amenity) => amenity.id !== id
    );
    setFilteredAmenities(updatedAmenities); // Update displayed list
  };
  const FetchAmenitiesSelect = async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/hotelAmenities/get-amenities?hotel=${hotel}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      // Set only active amenities
      setFetchAmenities(response.data.amenities);
    } catch (error) {
      console.error("Error fetching amenities:", error);
    }
  };

  useEffect(() => {
    FetchAmenitiesSelect(); // Fetch amenities

    if (data?.amenities) {
      // Extract selected IDs and set them in the state
      const selectedIds = data.amenities.map((amenity) => amenity.id);
      setSelectedAmenities(selectedIds);

      // Set full details of selected amenities for display
      setFilteredAmenities(data.amenities);
    }
  }, [data]);

  const handleImagesChange = useCallback((newImages) => {
    setImageList(newImages);
  }, []);

  const areImagesModified = (newImages, originalImages) => {
    if (newImages.length !== originalImages.length) return true;
    return newImages.some(
      (newImage, index) =>
        newImage.link !== originalImages[index]?.link ||
        newImage.fileName !== originalImages[index]?.fileName
    );
  };

  // Fetch Room data
  useEffect(() => {
    const fetchOfferData = async () => {
      if (data?.id) {
        try {
          const response = await axios.get(
            `${import.meta.env.VITE_APP_BASE_API_URL}/roomTypes/FindOne?id=${
              data.id
            }`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );

          const Room = response.data.room;
          setImageList(Room.Images);
          // const extraFields = Array.isArray(Room.extraFields)
          //   ? Room.extraFields
          //   : JSON.parse(Room.extraFields || '{}').extraFields || [];

          // setExtraFields(extraFields); // Initialize state with fetched extra fields
        } catch (error) {
          console.error("Error fetching offer:", error);
        }
      }
    };

    fetchOfferData();
  }, [data?.id, user.data.accessToken]);

  const handleExtraFieldChange = (index, key, value) => {
    setExtraFields((prev) => {
      const updatedFields = [...prev]; // Shallow copy the array
      updatedFields[index] = { ...updatedFields[index], [key]: value }; // Update the specific field
      return updatedFields; // Return the new array
    });
  };

  const handleCancel = () => {
    setShowModal(false);
    setModalOpen(false);
  };

  const onFinish = async (values) => {
    try {
      function extractIds(array) {
        return array.map((item) => item.id);
      }

      // // Construct the extraFields object
      // const extraFieldsObject = {
      //   extraFields: ExtraFields.map((field) => ({
      //     title: field.title || "",
      //     description: field.description || "",
      //     icon: field.icon || [],
      //   })),
      // };

      // // Stringify the extraFields object
      // const extraFieldsString = JSON.stringify(extraFieldsObject);

      // Construct the payload
      const payload = {
        name: {
          arabic: values.name,
          english: values.name_en,
          russian: values.name_ru,
        },
        // bedding: {
        //   arabic: values.bedding,
        //   english: values.bedding_en,
        //   russian: values.bedding_ru,
        // },
        hotel: hotel, // Ensure this is correctly populated
        images: imageList.map((img) => ({
          link: img.link,
          fileName: img.name,
        })), // Format image list
        amenities: extractIds(filteredAmenities),
        // living_room: {
        //   arabic: values.living_room,
        //   english: values.living_room_en,
        //   russian: values.living_room_ru,
        // },
        // size: {
        //   arabic: values.size,
        //   english: values.size_en,
        //   russian: values.size_ru,
        // },
        // view: {
        //   arabic: values.view,
        //   english: values.view_en,
        //   russian: values.view_ru,
        // },
        // extraFields: JSON.stringify({
        //   extraFields: ExtraFields.map((field) => ({
        //     title: field.title || "", // Ensure title is set properly
        //     description: field.description || "",
        //     icon: field.icon || [],
        //   })),
        // }),
      };
      // Debugging payload before submission
      console.log("Payload:", payload);

      setLoading(true);

      // Make the API call
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/roomTypes?id=${data?.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      console.log("Update Successful:", response);

      // Refetch data and close modal
      refetch();
      setModalOpen(false);
    } catch (error) {
      // Improved error handling
      console.error(
        "Error updating Room:",
        error.response?.data || error.message
      );
      message.error("Failed to update the room. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      handleDelayedOpen();
    } else {
      setShowModal(false);
    }
  }, [modalOpen]);
  const handleDelayedOpen = () => {
    setTimeout(() => {
      setShowModal(true); // Show the modal after 3 seconds
    }, 300);
  };

  const handleFieldChange = (index, key, value) => {
    setExtraFields((prevFields) => {
      // Make sure to create a new copy of the fields to trigger re-render
      const updatedFields = [...prevFields];
      updatedFields[index] = { ...updatedFields[index], [key]: value };
      return updatedFields;
    });
  };
  return (
    <Modal
      okText={"Save"}
      onOk={() => {
        form
          .validateFields()
          .then(onFinish)
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ disabled: loading }}
      onCancel={handleCancel}
      open={modalOpen}
    >
      {showModal ? (
        <Form
          form={form}
          name="edit-offer"
          layout="vertical"
          initialValues={{
            name: data?.name?.arabic,
            name_en: data?.name?.english,
            name_ru: data?.name?.russian,
            // bedding: data?.bedding?.arabic,
            // bedding_en: data?.bedding?.english,
            // bedding_ru: data?.bedding?.russian,
            // bedding_ar: data?.bedding?.arabic,
            // living_room_en: data?.livingRoom?.english,
            // living_room_ru: data?.livingRoom?.russian,
            // living_room: data?.livingRoom.arabic,
            // size: data?.size?.arabic,
            // size_en: data?.size?.english,
            // size_ru: data?.size?.russian,
            // view: data?.view?.arabic,
            // view_en: data?.view?.english,
            // view_ru: data?.view?.russian,
            // view_ar: data?.view?.arabic,
            hotel: data?.hotel,
          }}
        >
          <Form.Item
            name="images"
            label="Photo"
            rules={[
              {
                required: true,
                message: "Please upload at least one image!",
                validator: (_, value) => {
                  // Validate if imageList is not empty
                  if (imageList && imageList.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please upload at least one image")
                  );
                },
              },
            ]}
          >
            <UploadMultipleImagesHotel
              data={imageList}
              onChange={handleImagesChange}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label="Room Name Arabic"
            rules={[
              {
                required: true,
                message: "Please input a name!",
              },
              {
                whitespace: true,
                message: "Please input a valid name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name_en"
            label="Room Name English"
            rules={[
              {
                required: true,
                message: "Please input a name!",
              },
              {
                whitespace: true,
                message: "Please input a valid name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name_ru"
            label="Room Name Russian"
            rules={[
              {
                required: true,
                message: "Please input a name!",
              },
              {
                whitespace: true,
                message: "Please input a valid name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="amenities" label="Amenities">
            {/* Select Dropdown for Amenities */}
            <Select
              mode="multiple" // Enable multiple selection
              style={{ width: "100%" }}
              onChange={handleSelectAmenity} // Handle selection changes
              value={selectedAmenities} // Bind selected IDs to the Select
              placeholder="Select amenities"
            >
              {FetchAmenities.map((amenity) => (
                <Select.Option
                  key={amenity.id}
                  value={
                    amenity.id != Number ? amenity.id : "Not here Amenities"
                  }
                >
                  {amenity.name.english}
                </Select.Option>
              ))}
            </Select>

            {/* Display Selected Amenities */}
            {filteredAmenities.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {filteredAmenities.map((amenity) => (
                  <div
                    key={amenity.id}
                    style={{
                      marginBottom: "10px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #ddd",
                      borderRadius: "4px",
                    }}
                  >
                    <span>{amenity.name.english}</span>
                    <Button
                      danger
                      onClick={() => handleRemoveAmenity(amenity.id)} // Remove selected amenity
                      size="small"
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </Form.Item>

          {/* Other fields */}
          {/* <Form.Item
          name="bedding"
          label="Room Bedding"
          rules={[
            {
              required: true,
              message: "Please input a bedding!",
            },
            {
              whitespace: true,
              message: "Please input a valid bedding!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="bedding_en"
          label="Room Bedding English"
          rules={[
            {
              required: true,
              message: "Please input a bedding!",
            },
            {
              whitespace: true,
              message: "Please input a valid bedding!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="bedding_ru"
          label="Room Bedding Russian"
          rules={[
            {
              required: true,
              message: "Please input a bedding!",
            },
            {
              whitespace: true,
              message: "Please input a valid bedding!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="living_room"
          label="Living Room"
          rules={[
            {
              required: true,
              message: "Please input living room details!",
            },
            {
              whitespace: true,
              message: "Please input valid living room details!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="living_room_en"
          label="Living Room English"
          rules={[
            {
              required: true,
              message: "Please input living room details!",
            },
            {
              whitespace: true,
              message: "Please input valid living room details!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="living_room_ru"
          label="Living Room Russian"
          rules={[
            {
              required: true,
              message: "Please input living room details!",
            },
            {
              whitespace: true,
              message: "Please input valid living room details!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="size"
          label="Room Size"
          rules={[
            {
              required: true,
              message: "Please input the room size!",
            },
            {
              whitespace: true,
              message: "Please input a valid size!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="size_en"
          label="Room Size English"
          rules={[
            {
              required: true,
              message: "Please input the room size!",
            },
            {
              whitespace: true,
              message: "Please input a valid size!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="size_ru"
          label="Room Size Russian"
          rules={[
            {
              required: true,
              message: "Please input the room size!",
            },
            {
              whitespace: true,
              message: "Please input a valid size!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="view"
          label="View"
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
              message: "Please input a valid view!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="view_en"
          label="View English"
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
              message: "Please input a valid view!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="view_ru"
          label="View Russian"
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
              message: "Please input a valid view!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {console.log("check ", typeof data.extraFields === 'string')}

        {typeof data.extraFields === 'string' && data.extraFields.trim() !== '' && (
          <>
            {JSON.parse(data.extraFields).extraFields && JSON.parse(data.extraFields).extraFields.length > 0 ? (
              <div
                style={{
                  marginBottom: "16px",
                  border: "1px solid #ddd",
                  padding: "16px",
                  borderRadius: "8px",
                  position: "relative",
                }}
              >
                {JSON.parse(data.extraFields).extraFields.map((field, index) => (
                  <div key={index}>
                    <Form.Item
                      name={`icon_${index}`}
                      label={`Room Icon ${index + 1}`}
                    >
                      {field.icon ? null : null}
                      <UploadMultipleImagesHotel data={field.icon} onChange={handleImagesChange} />
                      {imageList ? null : <p>Please upload at least one image</p>}
                    </Form.Item>

                    <Form.Item
                      name={`title_${index}`}
                      label={`Room Title ${index + 1}`}
                    >
                      <Input
                        value={ExtraFields[index]?.title || ''} // Use the current state as the initial value
                        onChange={(e) => handleFieldChange(index, "title", e.target.value)}
                      />
                    </Form.Item>




                    <Form.Item
                      name={`description_${index}`}
                      label={`Room Description ${index + 1}`}
                      rules={[
                        { message: "Please input a description!" },
                        { whitespace: true, message: "Please input a valid description!" },
                      ]}
                    >
                      {field.description ? null : null} 
                      <ReactQuill
                        theme="snow"
                        value={ExtraFields[index]?.description || ''}
                        onChange={(value) => handleFieldChange(index, "description", value)}

                      />

                    </Form.Item>
                  </div>
                ))}
              </div>
            ) : null}

          </>
        )} */}
        </Form>
      ) : (
        "loading.."
      )}
    </Modal>
  );
};

export default ViewRoomModal;
