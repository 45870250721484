import { Button, message } from "antd";
import dayjs from "dayjs";
import { BiSpreadsheet } from "react-icons/bi";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx"; // Import SheetJS library

const DownloadDataButtonGuest = ({ data , hotel }) => {
    const selectedHotel = useSelector(
        (state) => state.hotelReducer.hotel?.hotelName || state.hotelReducer.defaultHotel?.hotelName
    );

    const [messageApi, contextHolder] = message.useMessage();

    const exportToExcel = () => {
        if (!data || data.length === 0) {
            messageApi.open({
                type: "error",
                content: "There's no data to be exported",
            });
            return;
        }

        try {
            console.log(selectedHotel)
            // Handle both object and string values for selectedHotel
            const hotelName = typeof selectedHotel === 'object'
                ? selectedHotel.english : selectedHotel ;

            // Map the data to a simple array of objects (as needed for exporting)
            const exportData = data.map((Guest) => {
                const profile = Guest.userProfile || {}; // In case profile is missing

                // Combine first name and last name to form full name
                const fullName = `${profile.firstName || ''} ${profile.lastName || ''}`.trim();

                return {
                    Email: Guest.email || "N/A", // Email of the creator
                    Name: fullName || "N/A", // Full name of the user (firstName + lastName)
                    Status: Guest.active ? "Active" : "Inactive", // Status based on 'active' field
                };
            });

            // Create a new workbook and add a worksheet
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(exportData);

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, "Guests");

            // Generate Excel file and trigger download
            XLSX.writeFile(
                workbook,
                `${hotelName} - GuestsData - ${dayjs().format("YYYY-MM-DD")}.xlsx`
            );
        } catch (error) {
            console.error('Error exporting data:', error);
            messageApi.open({
                type: "error",
                content: "An error occurred while exporting data.",
            });
        }
    };

    return (
        <Button icon={<BiSpreadsheet />} type="primary" onClick={exportToExcel}>
            {contextHolder}
            Export Sheet
        </Button>
    );
};

export default DownloadDataButtonGuest;
