import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";

const AddRoomModal = ({ setAddModalOpen, addModalOpen, hotel, refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [CurrentHotel, setCurrentHotel] = useState(hotel);
  const [imageList, setImageList] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [SelectAmenities, setSelectAmenities] = useState([]); // Available amenities
  const [payloadAmenities, setPayloadAmenities] = useState([]); // Selected amenities
  const [selectedAmenityId, setSelectedAmenityId] = useState(null); // Currently selected amenity

  // Fetch amenities on component mount
  const FetchAmenitiesSelect = async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/hotelAmenities/get-amenities?hotel=${hotel ? hotel : 100378095}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      // Set only active amenities
      setSelectAmenities(response.data.amenities);
    } catch (error) {
      console.error("Error fetching amenities:", error);
    }
  };

  useEffect(() => {
    FetchAmenitiesSelect(); // Fetch amenities when the component is mounted
  }, []);

  // Add selected amenity to payloadAmenities
  const handleSelectAmenity = (value) => {
    if (!payloadAmenities.includes(value)) {
      setPayloadAmenities([...payloadAmenities, value]); // Add the amenity id to the list
    }
    setSelectedAmenityId(value); // Set the selected amenity ID
  };

  // Remove an amenity from the payloadAmenities (by id)
  const handleRemoveAmenity = (id) => {
    setPayloadAmenities(
      payloadAmenities.filter((amenityId) => amenityId !== id)
    );
  };

  const filterImages = (images) => {
    return images.map(({ fileName, link }) => ({ fileName, link }));
  };

  const onFinish = async (values) => {
    const filteredImages = filterImages(values.image);

    // Map amenities to create an array of objects with title, description, and icon for each amenity
    const extraFields = amenities.map((amenity) => {
      return {
        title: values[`title_${amenity.id}`],
        description: values[`description_${amenity.id}`],
        icon: filterImages(values[`icon_${amenity.id}`]), // Extract the icon for each amenity
      };
    });

    const payload = {
      name: {
        arabic: values.name,
        english: values.name_en,
        russian: values.name_ru,
      },
      amenities: payloadAmenities,
      images: filteredImages,
      hotel: CurrentHotel,
      // bedding: {
      //   arabic: values.bedding,
      //   english: values.bedding_en,
      //   russian: values.bedding_ru,
      // },
      // living_room: {
      //   arabic: values.living_room,
      //   english: values.living_room_en,
      //   russian: values.living_room_ru,
      // },
      // size: {
      //   arabic: values.size,
      //   english: values.size_en,
      //   russian: values.size_ru,
      // },
      // view: {
      //   arabic: values.view,
      //   english: values.view_en,
      //   russian: values.view_ru,
      // },
      // extra_fields: {extraFields}, // Now passing extra_fields as an array of objects
    };

    console.log("Payload:", payload);

    setLoading(true);

    try {
      await axios.post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/roomTypes/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      refetch();
      form.resetFields();
      setAddModalOpen(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchAmenitiesSelect();
  }, [refetch]); // Trigger on refetch changes

  const handleCancel = () => {
    form.resetFields();
    setAddModalOpen(false);
  };

  return (
    <Modal
      okText={"Add Room"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      confirmLoading={loading}
      open={addModalOpen}
    >
      <Form form={form} layout="vertical" name="addRoomModal">
        {/* Upload room images */}
        <Form.Item
          name="image"
          label="Room Photos"
          rules={[
            {
              required: true,
              message: "Please upload at least one image",
            },
          ]}
        >
          <UploadMultipleImagesHotel />
        </Form.Item>

        {/* Room name */}
        <Form.Item
          name="name"
          label="Room Name"
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
            {
              whitespace: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name_en"
          label="Room Name English"
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
            {
              whitespace: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="name_ru"
          label="Room Name Russian"
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
            {
              whitespace: true,
              message: "Please input a valid name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="amenities"
          label="Amenities"
          rules={[
            {
              required: true,
              message: "Please select Amenities!",
            },
          ]}
        >
          {/* Select Component */}
          <Select
            onChange={handleSelectAmenity}
            value={selectedAmenityId}
            style={{ width: "100%" }}
          >
            {SelectAmenities.map((amenity) => (
              <Select.Option key={amenity.id} value={amenity.id}>
                {amenity.name.english} {/* Displaying the English name */}
              </Select.Option>
            ))}
          </Select>

          {/* Display selected amenities using id and look up name */}
          {payloadAmenities.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              {payloadAmenities.map((amenityId) => {
                // Find the selected amenity by id
                const selectedAmenity = SelectAmenities.find(
                  (amenity) => amenity.id === amenityId
                );
                return (
                  selectedAmenity && (
                    <div
                      key={amenityId}
                      style={{
                        marginBottom: "5px",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{selectedAmenity.name.english}</span>{" "}
                      {/* Display the name */}
                      <Button
                        danger
                        onClick={() => handleRemoveAmenity(amenityId)}
                        size="small"
                        style={{ marginLeft: "10px" }}
                      >
                        Remove
                      </Button>
                    </div>
                  )
                );
              })}
            </div>
          )}
        </Form.Item>

        {/* <Form.Item
          name="bedding"
          label="Room Bedding"
          rules={[
            {
              required: true,
              message: "Please input a bedding!",
            },
            {
              whitespace: true,
              message: "Please input a valid bedding!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="bedding_en"
          label="Room Bedding English"
          rules={[
            {
              required: true,
              message: "Please input a bedding!",
            },
            {
              whitespace: true,
              message: "Please input a valid bedding!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="bedding_ru"
          label="Room Bedding Russian"
          rules={[
            {
              required: true,
              message: "Please input a bedding!",
            },
            {
              whitespace: true,
              message: "Please input a valid bedding!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="living_room"
          label="Living Room"
          rules={[
            {
              required: true,
              message: "Please input living room details!",
            },
            {
              whitespace: true,
              message: "Please input valid living room details!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="living_room_en"
          label="Living Room English"
          rules={[
            {
              required: true,
              message: "Please input living room details!",
            },
            {
              whitespace: true,
              message: "Please input valid living room details!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="living_room_ru"
          label="Living Room Russian"
          rules={[
            {
              required: true,
              message: "Please input living room details!",
            },
            {
              whitespace: true,
              message: "Please input valid living room details!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="size"
          label="Room Size"
          rules={[
            {
              required: true,
              message: "Please input the room size!",
            },
            {
              whitespace: true,
              message: "Please input a valid size!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="size_en"
          label="Room Size English"
          rules={[
            {
              required: true,
              message: "Please input the room size!",
            },
            {
              whitespace: true,
              message: "Please input a valid size!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="size_ru"
          label="Room Size Russian"
          rules={[
            {
              required: true,
              message: "Please input the room size!",
            },
            {
              whitespace: true,
              message: "Please input a valid size!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="view"
          label="View"
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
              message: "Please input a valid view!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="view_en"
          label="View English"
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
              message: "Please input a valid view!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="view_ru"
          label="View Russian"
          rules={[
            {
              required: true,
            },
            {
              whitespace: true,
              message: "Please input a valid view!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

        {/* Add Amenities button
        <Button
          type="primary"
          style={{ marginBottom: 16 }}
          onClick={handleAddAmenity}
        >
          Add Amenities
        </Button>

        <div>
          {amenities.map((amenity) => (
            <div
              key={amenity.id}
              style={{
                marginBottom: "16px",
                border: "1px solid #ddd",
                padding: "16px",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Form.Item
                name={`icon_${amenity.id}`}
                label="Room Icon"
                rules={[{ required: true, message: "Please upload an icon!" }]}
              >
                <UploadMultipleImagesHotel />
              </Form.Item>

              <Form.Item
                name={`title_${amenity.id}`}
                label="Room Title"
                rules={[
                  { required: true, message: "Please input a title!" },
                  { whitespace: true, message: "Please input a valid title!" },
                ]}
              >
                <Input placeholder="Enter room title" />
              </Form.Item>

              <Form.Item
                name={`description_${amenity.id}`}
                label="Room Description"
                rules={[
                  { required: true, message: "Please input a description!" },
                  { whitespace: true, message: "Please input a valid description!" },
                ]}
              >
                <ReactQuill theme="snow" />
              </Form.Item>

              <Button
                type="link"
                danger
                style={{ position: "absolute", top: "8px", right: "8px" }}
                onClick={() => handleRemoveAmenity(amenity.id)}
              >
                Remove
              </Button>
            </div>
          ))}
        </div> */}
      </Form>
    </Modal>
  );
};

export default AddRoomModal;
