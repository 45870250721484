import { NavLink, Outlet } from "react-router-dom";
import "../../assets/scss/Dashboard.scss";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  CommentOutlined,
  TrophyOutlined,
  CarFilled,
  HistoryOutlined,
} from "@ant-design/icons";
import stellaLogo from "../../assets/StellaDiMare_logo.png";
import kLogo from "../../assets/klogo.svg";
import { Button, Layout, Menu } from "antd";
import { useState } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import UsersIcon from "../../components/icons/UsersIcon";
import OrdersIcon from "../../components/icons/OrdersIcon";
import HousekeepingIcon from "../../components/icons/HousekeepingIcon";
import RoomServiceIcon from "../../components/icons/RoomServiceIcon";
import MaintenanceIcon from "../../components/icons/MaintenanceIcon";
import LogoutIcon from "../../components/icons/LogoutIcon";
import OutletsIcon from "../../components/icons/OutletsIcon";
import ExcursionsIcon from "../../components/icons/ExcursionsIcon";
import NotificationsIcon from "../../components/icons/NotificationsIcon";
import DashboardIcon from "../../components/icons/DashboardIcon";
import OffersIcon from "../../components/icons/OffersIcon";
import FrontOfficeIcon from "../../components/icons/FrontOfficeIcon";
import NotificationToast from "../../components/NotificationToast";
import ActivitiesAndFacilitiesIcon from "../../components/icons/ActivitiesAndFacilities";
import RoomTypes from "../../pages/RoomTypes/RoomTypes";
import RoomTypesIcon from "../../components/icons/RoomTypesIcon";
import GuestList from "../../pages/UsersTabs/GuestList";
import GuestListIcon from "../../components/icons/GuestListIcon";

const { Content, Sider } = Layout;

const DashboardLayout = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  // ROLE GROUPS

  const roleBasedTabs = {
    orders: {
      label: "Orders",
      icon: <OrdersIcon />,
      permissions: [1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14],
      url: "orders",
    },
    users: {
      label: "Users",
      icon: <UsersIcon />,
      permissions: [1, 12, 2, 3, 5, 7, 9, 14],
      children: [
        {
          label: "Marketing Admins",
          key: "loma",
          permissions: [1, 2, 12],
          url: "marketingadmins",
        },
        {
          label: "IT Admins",
          key: "loita",
          permissions: [1, 2, 12],
          url: "itadmins",
        },
        {
          label: "IT Staff",
          key: "loits",
          permissions: [1, 2, 12, 14],
          url: "itstaff",
        },
        {
          label: "Admins",
          key: "loa",
          permissions: [1, 2, 12, 3, 14],
          url: "adminlist",
        },
        {
          label: "Front Office",
          key: "fo",
          permissions: [1, 2, 12, 3, 14],
          url: "folist",
        },
        {
          label: "RS Supervisors",
          key: "rssuper",
          permissions: [1, 2, 12, 3, 9, 14],
          url: "rssuper",
        },
        {
          label: "RS Staff",
          key: "rsstaff",
          permissions: [1, 2, 12, 3, 9, 14],
          url: "rsstaff",
        },
        {
          label: "HK Supervisors",
          key: "hksuper",
          permissions: [1, 2, 12, 3, 7, 14],
          url: "hksuper",
        },
        {
          label: "HK Staff",
          key: "hkstaff",
          permissions: [1, 2, 12, 3, 7, 14],
          url: "hkstaff",
        },
        {
          label: "MN Supervisors",
          key: "mnsuper",
          permissions: [1, 2, 12, 3, 5, 14],
          url: "mnsuper",
        },
        {
          label: "MN Staff",
          key: "mnstaff",
          permissions: [1, 2, 12, 3, 5, 14],
          url: "mnstaff",
        },
      ],
    },
    GuestList: {
      label: "Guest List",
      icon: <GuestListIcon />,
      permissions: [1, 2, 3, 12],
      url: "guests",
    },
    FrontOffice: {
      label: "Front Office",
      icon: <FrontOfficeIcon />,
      permissions: [14, 12, 3, 2, 1],
      url: "frontoffice",
    },
    RoomTypes: {
      label: "Room Types",
      icon: <RoomTypesIcon />,
      permissions: [2, 1],
      url: "roomtypes",
    },
    roomService: {
      label: "Room Service",
      icon: <RoomServiceIcon />,
      permissions: [1, 2, 3, 9, 12, 14],
      url: "roomservice",
    },
    housekeeping: {
      label: "Housekeeping",
      icon: <HousekeepingIcon />,
      permissions: [1, 3, 7, 12, 14],
      url: "housekeeping",
    },
    maintenance: {
      label: "Maintenance",
      icon: <MaintenanceIcon />,
      permissions: [1, 3, 5, 12, 14],
      url: "maintenance",
    },
    activitiesFacilities: {
      label: "Activities & Facilities",
      icon: <ActivitiesAndFacilitiesIcon />,
      permissions: [1, 2],
      url: "facilities",
    },
    outlets: {
      label: "Outlets",
      icon: <OutletsIcon />,
      permissions: [1, 2],
      url: "outlet",
    },
    excursions: {
      label: "Excursions",
      icon: <ExcursionsIcon />,
      permissions: [1, 2],
      url: "excursions",
    },
    offers: {
      label: "Offers",
      icon: <OffersIcon />,
      permissions: [1, 2],
      url: "offers",
    },
    hotels: {
      label: "Hotels",
      icon: <HomeOutlined />,
      permissions: [1, 2],
      url: "hotels",
    },
    reservations: {
      label: "Reservations",
      icon: <HomeOutlined />,
      permissions: [1, 2, 12, 4, 3, 14], // Combined role IDs
      url: "reservations",
    },
    amenities: {
      label: "Amenities",
      icon: <HomeOutlined />,
      permissions: [1, 2],
      url: "amenities",
    },
    hotelAreas: {
      label: "Hotel Areas",
      icon: <HomeOutlined />,
      permissions: [1, 2, 12, 14],
      url: "hotelareas",
    },
    hotelRooms: {
      label: "Hotel Rooms",
      icon: <HomeOutlined />,
      permissions: [1, 2, 12, 14],
      url: "hotelrooms",
    },
    pushNotifications: {
      label: "Push Notifications",
      icon: <NotificationsIcon />,
      permissions: [1, 2, 12, 14],
      url: "push",
    },
    loyaltyProgram: {
      label: "Loyalty Program",
      icon: <TrophyOutlined />,
      permissions: [1, 2],
      children: [
        {
          label: "Loyalty Tiers",
          key: "loyaltyTiers",
          permissions: [1, 2],
          url: "loyaltytiers",
        },
        {
          label: "Loyalty Users",
          key: "loyaltyUsers",
          permissions: [1, 2],
          url: "loyaltyusers",
        },
      ],
    },
    reviews: {
      label: "Reviews",
      icon: <CommentOutlined />,
      permissions: [1, 2],
      url: "reviews",
    },
    actionsLog: {
      label: "Actions Log",
      icon: <HistoryOutlined />,
      permissions: [1, 2],
      url: "log",
    },
  };

  const items = [
    {
      label: <NavLink to="dashboard">Dashboard</NavLink>,
      key: "dash",
      icon: <DashboardIcon />,
    },
    ...Object.entries(roleBasedTabs)
      .filter(([key, { permissions }]) => permissions.includes(user?.roles?.id))
      .map(([key, { label, icon, children, url }]) => ({
        label: <NavLink to={url}>{label}</NavLink>,
        key,
        icon,
        children: children
          ? children
            .filter(({ permissions }) =>
              permissions.includes(user?.roles?.id)
            )
            .map((child) => ({
              label: <NavLink to={child.url}>{child.label}</NavLink>,
              key: child.key,
            }))
          : undefined,
      })),
    {
      icon: <LogoutIcon />,
      label: <NavLink to="/logout">Logout</NavLink>,
      key: "logout",
    },
    { type: "divider" },
  ];

  const [collapsed, setCollapsed] = useState(false);
  const [menuWidth, setMenuWidth] = useState("15%");
  const toggleCollapse = (prevCollapse) => {
    console.log(prevCollapse);
    setCollapsed(!collapsed);
    if (prevCollapse) {
      setMenuWidth("15%");
    } else {
      setMenuWidth("80px");
    }
  };

  return (
    <div className="super-admin-layout">
      <NotificationToast />

      <Layout>
        <Layout>
          <Sider
            collapsed={collapsed}
            width={menuWidth}
            style={{
              overflow: "auto",
              overflowX: "hidden",
              height: "100vh",
              position: "fixed",
              background: "#1e293b",
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <div className="logo-switch">
              <div className="logo">
                <img src={stellaLogo} alt="" />
              </div>
            </div>
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{
                borderRight: 1,
              }}
              items={items}
            />
            <Button
              type="primary"
              onClick={() => toggleCollapse(collapsed)}
              style={{ borderRadius: 0, width: "100%", marginTop: 16 }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </Sider>
          <Layout>
            <DashboardHeader menuWidth={menuWidth} />
            <Content
              style={{
                padding: 24,
                marginLeft: menuWidth,
                minHeight: "100vh",
                background: "#fcfcfc",
              }}
            >
              <Outlet />
            </Content>
            <div id="kinetics-socket">
              <div className="kinetics-logo-box">
                <a href="https://www.kineticsdubai.com/">
                  <img src={kLogo} alt="" />
                </a>
                <p>V 1.0</p>
              </div>
              <p>
                Designed & Developed by{" "}
                <a href="https://www.kineticsdubai.com/">Kinetics</a> ©
                {new Date().getFullYear()}
              </p>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default DashboardLayout;
