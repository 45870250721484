import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ViewRoomModal from "./ViewRoomModal";
import AddRoomModal from "./AddRoomModal";
import Swal from "sweetalert2";
import DOMPurify from 'dompurify';

// render description html
const renderHTML = (htmlString) => {
  const cleanHTML = DOMPurify.sanitize(htmlString);
  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};

const RoomTypes = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: ["name", "english"],
      render: (htmlContent) => renderHTML(htmlContent),

    },
    // {
    //   title: "Bedding",
    //   dataIndex: ["bedding", "english"],
    // },
    // {
    //   title: "Living Room",
    //   dataIndex: ["livingRoom", "english"],
    // },
    // {
    //   title: "view",
    //   dataIndex: ["view", "english"],
    // },
    // {
    //   title: "Size",
    //   dataIndex: ["size", "english"],
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => (
        <div className="row-actions">
          <Button
            style={{ marginRight: 16 }}
            onClick={() => handleViewClick(row)}
          >
            View
          </Button>
          <Button danger onClick={() => handleDeleteClick(row)}>
            Delete
          </Button>
        </div>
      ),
    },

  ];

  const fetchData = () => {
    setLoading(true);
    const { current, pageSize } = tableParams.pagination;

    axios
      .get(`${import.meta.env.VITE_APP_BASE_API_URL}/roomTypes/get-room-types/`, {

        params: {
          hotel: selectedHotel.hotelID,
          page: current,
          pageSize: pageSize,
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        console.log(response.data.rooms)
        setData(response.data.rooms);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.totalCount, // Assuming your API returns totalCount
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching RoomTypes:", error);
        setLoading(false);
      });
  };

  const handleViewClick = (record) => {
    setModalData(record);
    setViewModalOpen(true);
  };

  const handleDeleteClick = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Delete : ", auth.data.accessToken);
        axios
          .delete(`${import.meta.env.VITE_APP_BASE_API_URL}/roomTypes`, {
            params: {
              id: record.id
            },
            headers: {
              Authorization: `Bearer ${auth.data.accessToken}`,
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "Your offer has been deleted.", "success");
            fetchData(); // Refresh data after successful deletion
          })
          .catch((error) => {
            Swal.fire("Error!", "There was a problem deleting the offer.", "error");
            console.error("Error deleting offer:", error);
          });
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, selectedHotel.hotelID]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
        <Button
          type="primary"
          onClick={() => setAddModalOpen(true)}
          style={{ marginBottom: 16 }}
        >
          Add Room
        </Button>
      </div>


      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={tableParams.pagination}
        loading={loading}
        onChange={(pagination) => setTableParams({ ...tableParams, pagination })}
      />

      {/* Modal for Viewing Offer */}
      {viewModalOpen && (
        <ViewRoomModal
          hotel={selectedHotel.hotelID}
          modalOpen={viewModalOpen}
          setModalOpen={setViewModalOpen}
          data={modalData}
          refetch={fetchData}
        />
      )}

      {/* Modal for Adding Offer */}
      {addModalOpen && (
        <AddRoomModal
          refetch={fetchData}
          hotel={selectedHotel.hotelID}
          addModalOpen={addModalOpen}
          setAddModalOpen={() => setAddModalOpen(false)}
          onAddOffer={() => {
            fetchData();
            setAddModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default RoomTypes;
