import { Button, Form, Input, Modal } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";

const EditHotelAreaModal = ({ data, refetch }) => {
  const [modal, setModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false); // For handling fetch/loading states
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();
  const [description, setDescription] = useState("");

  // Effect to initialize description when `data` changes
  useEffect(() => {
    if (data) {
      setDescription(data.description || ""); // Safely set the description
    }
  }, [data]);

  const onFinish = async (values) => {
    try {
      setLoading(true); // Set loading state
      await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelAreas?id=${data.id}`,
        {
          name: values.areaName,
          description: description, // Use updated description
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      await refetch(); // Fetch updated data
      setModal(false);
      setIsModalVisible(false);
      Swal.fire("Success", "Hotel  Area updated successfully!", "success");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    setModal(false);
    setIsModalVisible(false);
  };

  const handleModalOpen = () => {
    setModal(true);
    // Wait for modal animation to complete
    setTimeout(() => setIsModalVisible(true), 300);
  };

  return (
    <>
      <Button onClick={handleModalOpen}>Edit</Button>
      <Modal
        title="Edit Hotel Area"
        visible={modal}
        onCancel={handleModalCancel}
        destroyOnClose
        okText="Edit Hotel"
        confirmLoading={loading} // Show loading on the OK button
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        centered
        afterClose={() => setIsModalVisible(false)} // Reset visibility state when closed
      >
        {isModalVisible ? (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              areaName: data?.name,
              areaDesc: description,
            }}
            name="editHotelAreaModal"
          >
            <Form.Item
              label="Name"
              name="areaName"
              rules={[{ required: true, message: "Please input the area name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name="areaDesc"
              rules={[{ required: true, message: "Please input the area description!" }]}
            >
              {isModalVisible && (
                <ReactQuill
                  theme="snow"
                  value={description} // Bind ReactQuill value to description state
                  onChange={setDescription} // Update description state on change
                />
              )}
            </Form.Item>
          </Form>
        ) : (
          <div>Loading...</div>
        )}
      </Modal>
    </>
  );
};

export default EditHotelAreaModal;
