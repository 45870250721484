import { Button, Table, Input } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../../../assets/scss/General.scss";

const LoyalityUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const columns = [
    { title: "ID", dataIndex: "id" },
    { title: "User Email", dataIndex: ["userDetails", "email"] },
    { title: "User Points", dataIndex: "totalPoints" },
    { title: "Expires on", dataIndex: "expiryDate" },
    {
      title: "Current Tier",
      dataIndex: ["tierDetails", "name"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Current Tier"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
              handleSearch(selectedKeys[0]);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              resetFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.tierDetails.name
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
    },
    {
      title: "Actions",
      render: (_, row) => <Link to={`${row.id}`}>View User</Link>,
    },
  ];

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {}, // Tracks active filters
  });

  const fetchUsers = () => {
    setLoading(true);
    const { current, pageSize } = tableParams.pagination;
    const { filters } = tableParams;

    // Include filters for API requests
    const filterParams = Object.entries(filters)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/loyaltyPoints/get-points?page=${current}&limit=${pageSize}${
          filterParams ? `&${filterParams}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        setUsers(res.data.usersLoyaltyPoints || []);
        setTableParams((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: res.data.totalCount,
          },
        }));
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleSearch = (value) => {
    setSearchText(value); // Update local state
    setTableParams((prev) => ({
      ...prev,
      filters: { ...prev.filters, ["tierDetails.name"]: value },
      pagination: {
        ...prev.pagination,
        current: 1, // Reset to first page for new search
      },
    }));
  };

  const resetFilters = () => {
    // Clear searchText and filters
    setSearchText("");
    setTableParams((prev) => ({
      ...prev,
      filters: {}, // Clear all filters
      pagination: {
        ...prev.pagination,
        current: 1, // Reset pagination
      },
    }));
    fetchUsers(); // Refetch data
  };

  useEffect(() => {
    fetchUsers();
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, tableParams.filters]); // Refetch when filters or pagination change

  const handleTableChange = (pagination) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
    }));
  };

  return (
    <div>
      <div className="top-section">
        <div className="exchange">
          <b>Current Exchange Rate </b>
          <p>Earn 1 USD = 1 point </p>
          <p>Redeem 100 point = 5 USD</p>
        </div>
      </div>
      <Table
        rowKey={(record) => record.id}
        dataSource={users}
        columns={columns}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default LoyalityUsers;
