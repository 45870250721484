import React from "react";

function GuestListIcon() {
    return (
        <span className="anticon ant-menu-item-icon">
            <svg fill="#ffffff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512.001 512.001" xml:space="preserve">

                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                <g id="SVGRepo_iconCarrier"> <g> <g> <path d="M156.683,411.479h-22.274v-19.652c0-8.907-7.246-16.153-16.153-16.153H82.367c-8.907,0-16.153,7.246-16.153,16.153v19.652 H43.941c-6.244,0-11.307,5.063-11.307,11.307v77.906c0,6.245,5.063,11.307,11.307,11.307h112.741 c6.244,0,11.307-5.063,11.307-11.307v-77.906C167.989,416.541,162.927,411.479,156.683,411.479z M116.845,411.479h-0.001H83.78 v-18.24h33.065V411.479z" /> </g> </g> <g> <g> <circle cx="259.839" cy="42.141" r="42.141" /> </g> </g> <g> <g> <path d="M467.168,326.163h-9.066v-54.005h7.442c2.221,0,4.022-1.801,4.022-4.022v-13.406c0-2.221-1.801-4.022-4.022-4.022H412.71 c2.989-8.683-0.201-18.595-8.286-23.747l-41.732-26.595l-0.331-54.776c-0.133-26.399-21.718-47.876-48.118-47.876 c-11.299,0-97.821,0-109.159,0c-26.4,0-47.985,21.477-48.118,47.876l-0.751,149.38c-0.057,11.23,9.001,20.379,20.23,20.435 c0.036,0,0.07,0,0.104,0c11.181,0,20.275-9.037,20.331-20.23l0.751-149.38c0.011-2.166,1.772-3.913,3.936-3.908 c2.165,0.006,3.915,1.762,3.915,3.926l0.009,341.787c0,13.476,10.924,24.4,24.4,24.4c13.476,0,24.4-10.924,24.4-24.4V292.57 h10.534v195.029c0,13.476,10.924,24.4,24.4,24.4c13.476,0,24.4-10.924,24.4-24.4c0-318.415-0.432-143.909-0.443-341.647 c0-2.323,1.863-4.217,4.186-4.256c2.323-0.038,4.249,1.794,4.324,4.116c0,0,0,0.001,0,0.002l0.398,65.869 c0.041,6.903,3.582,13.314,9.405,17.024l45.98,29.302v10.125c0,2.221,1.801,4.022,4.022,4.022h7.442v54.005h-9.066 c-6.738,0-12.199,5.462-12.199,12.199v139.941c0,6.738,5.462,12.199,12.199,12.199h9.066v13.177c0,4.595,3.726,8.321,8.321,8.321 c4.595,0,8.321-3.726,8.321-8.321v-13.177h35.877v13.177c0,4.595,3.726,8.321,8.321,8.321c4.595,0,8.321-3.726,8.321-8.321 v-13.177h9.065c6.738,0,12.199-5.462,12.199-12.199v-139.94C479.366,331.624,473.906,326.163,467.168,326.163z M441.46,326.163 h-35.873v-54.005h35.873V326.163z" /> </g> </g> </g>

            </svg>
        </span>
    );
}

export default GuestListIcon;
