import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const HandleTabClose = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const removePersistKey = () => {
      // Remove the Redux persisted state key
      localStorage.removeItem("persist:root");
    };

    // Set the tab-specific state (you can customize this based on your logic)
    const updateTabState = (setTabValue) => {
      const nm = 'tabs';
      const tm = 'tabid';
      const lStorage = localStorage;
      const sStorage = sessionStorage;

      const tabId = sStorage.getItem(tm) || ((newId) => {
        sStorage.setItem(tm, newId);
        return newId;
      })((Math.random() * 1e8).toFixed());

      let currentValue = JSON.parse(lStorage.getItem(nm) || '{}');
      const arrValue = Object.values(currentValue);

      if (setTabValue && typeof currentValue[tabId] === 'undefined' && !arrValue.reduce((sum, current) => sum + current, 0)) {
        lStorage.clear();
        currentValue = {};
      }

      currentValue[tabId] = setTabValue;
      lStorage.setItem(nm, JSON.stringify(currentValue));
    };

    // Set initial tab state to 1
    updateTabState(1);

    // Cleanup logic when the tab is closed or reloaded
    const onBeforeUnload = () => {
      updateTabState(0);
      removePersistKey();
    };

    // Add event listener for beforeunload event
    window.addEventListener("beforeunload", onBeforeUnload);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [dispatch]);

  return null; // No UI is rendered
};

export default HandleTabClose;
