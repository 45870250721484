import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";
import hotelReducer from "./slices/hotelSlice"; // Import reducers
import notificationsReducer from "./slices/notificationsSlice";
import orderReducer from "./slices/orderSlice";
import thunk from "redux-thunk";

// Configure localforage to use IndexedDB
localforage.config({
  driver: localforage.INDEXEDDB,
  name: "myApp",
  version: 1.0,
  storeName: "reduxPersist",
  description: "My app's persistent storage",
});

const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: ["hotelReducer"], // Persist only the hotelReducer
};

const rootReducer = combineReducers({
  hotelReducer,
  notificationsReducer,
  orderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
