import { Button, Form, Input, Modal } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const EditHotelModal = ({ data, refetch }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();

  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");


  const [isModalVisible, setIsModalVisible] = useState(false);

  // State for image list
  const [imageList, setImageList] = useState([]);

  const handleImagesChange = useCallback((newImages) => {
    setImageList(newImages);
  }, []);

  const areImagesModified = (newImages, originalImages) => {
    if (newImages.length !== originalImages.length) return true;
    return newImages.some((newImage, index) =>
      newImage.link !== originalImages[index]?.link ||
      newImage.fileName !== originalImages[index]?.fileName
    );
  };


  useEffect(() => {
    setDesc(data?.description?.english);
    setDescAR(data?.description?.arabic);
    setDescRU(data?.description?.russian);
    setImageList(data?.images ? data?.images : []);

  }, [modal, data]);

  const onFinish = async (values) => {
    if (imageList.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please upload at least one image before saving.",
      });
      return; // Prevent form submission
    }
    const payload = {
      name: {
        english: values.name,
        arabic: values.nameAR,
        russian: values.nameRU,
      },
      city: {
        english: values.city,
        arabic: values.cityAR,
        russian: values.cityRU,
      },
      country: {
        english: values.country,
        arabic: values.countryAR,
        russian: values.countryRU,
      },
      description: {
        english: desc, // Make sure these are the updated states
        russian: descRU,
        arabic: descAR,
      },
    };
    console.log(payload);
    // Add images to payload only if they’ve been modified
    if (areImagesModified(imageList, data.images || [])) {
      payload.images = imageList.map((img) => ({
        link: img.link,
        fileName: img.fileName,
      }));
    }

    setLoading(true);
    try {
      // Send the patch request
      await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotels?id=${data.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      // After success
      form.resetFields(); // Reset form fields
      setIsModalVisible(false);
      refetch(); // Refetch data
      setModal(false); // Close the modal
    } catch (error) {
      // Handle error
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An unexpected error occurred",
      });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setModal(false);
  };

  const handleModalOpen = () => {
    setModal(true);
    // Wait for modal animation to complete
    setTimeout(() => setIsModalVisible(true), 300);
  };


  return (
    <>
      <Button onClick={handleModalOpen}>View</Button>
      <Modal
        onCancel={handleModalCancel}
        open={modal}
        destroyOnClose
        okText={"Edit Hotel"}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        centered
        confirmLoading={loading}
        afterClose={() => setIsModalVisible(false)}
      >
        {isModalVisible ? <Form
          form={form}
          layout="vertical"
          name="editHotelModal"
          initialValues={{
            name: data?.name?.english,
            nameRU: data?.name?.russian,
            nameAR: data?.name?.arabic,
            city: data?.city?.english,
            cityRU: data?.city?.russian,
            cityAR: data?.city?.arabic,
            country: data?.country?.english,
            countryRU: data?.country?.russian,
            countryAR: data?.country?.arabic,
            description: desc,
            descriptionAR: descAR,
            descriptionRU: descRU,
          }}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input a name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Name in Russian" name="nameRU" rules={[{ required: true, message: "Please input a name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Name in Arabic" name="nameAR" rules={[{ required: true, message: "Please input a name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="country" name="country" rules={[{ required: true, message: "Please input a country!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="country in Russian" name="countryRU" rules={[{ required: true, message: "Please input a country!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="country in Arabic" name="countryAR" rules={[{ required: true, message: "Please input a country!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="city" name="city" rules={[{ required: true, message: "Please input a city!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="city in Russian" name="cityRU" rules={[{ required: true, message: "Please input a city!" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="city in Arabic" name="cityAR" rules={[{ required: true, message: "Please input a city!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="images">
            <UploadMultipleImagesHotel data={imageList} onChange={handleImagesChange} />
            {imageList ? null : <p>Please upload at least one image</p>}
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
          >
            <ReactQuill theme="snow" value={desc} onChange={setDesc} />
          </Form.Item>

          <Form.Item
            name="descriptionRU"
            label="Description in Russian"
          >
            <ReactQuill theme="snow" value={descRU} onChange={setDescRU} />
          </Form.Item>

          <Form.Item
            name="descriptionAR"
            label="Description in Arabic"
          >
            <ReactQuill theme="snow" value={descAR} onChange={setDescAR} />
          </Form.Item>

        </Form> : 'loadinng...'}
      </Modal>
    </>
  );
};

export default EditHotelModal;
