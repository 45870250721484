import { Select, message } from "antd";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const OrderStatusSelect = ({ status, row, user, refetch }) => {
  const [currentStatus, setCurrentStatus] = useState(status);
  const [selectLoading, setSelectLoading] = useState(false);

  // Roles 
  const Housekeeping_roles = [7, 8]
  const Maintenance_roles = [5, 6]
  const Room_Service_roles = [9, 10]
  const IT_roles = [12, 13, 14]
  const Front_Office_roles = [4]
  const KAdmin_roles = [1]
  const Marketing_roles = [2]
  const Admin_roles = [3]
  console.log("user", user)
  console.log("row", row)
  // Check if the select should be disabled
  const isUnrestrictedRole =
    IT_roles.includes(user.roles.id) ||
    KAdmin_roles.includes(user.roles.id) ||
    Marketing_roles.includes(user.roles.id) ||
    Admin_roles.includes(user.roles.id);

  const isRoleSpecificMatch =
    (Room_Service_roles.includes(user.roles.id) && row.orderType.english === "Room Service") ||
    (Housekeeping_roles.includes(user.roles.id) && row.orderType.english === "Housekeeping") ||
    (Maintenance_roles.includes(user.roles.id) && row.orderType.english === "Maintenance") ||
    (Front_Office_roles.includes(user.roles.id) && row.orderType.english === "Front Office");

  const isDisabled = !(isUnrestrictedRole || isRoleSpecificMatch);
  const handleOrderStatusChange = async (val) => {
    if (val !== currentStatus) {
      setSelectLoading(true);
      try {
        await axios.patch(
          `${import.meta.env.VITE_APP_BASE_API_URL}/orders?id=${row.id}`,
          {
            status: val,
          },
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
        setCurrentStatus(val);
        setSelectLoading(false);
        message.success("Order status updated successfully");

        // Optionally refetch data
        if (refetch) refetch();
      } catch (err) {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text:
            err.response?.data?.message ||
            "Failed to update order status.",
        }).then(() => {
          setSelectLoading(false);
        });
      }
    }
  };

  return (
    <Select
      disabled={isDisabled || selectLoading}
      style={{ width: "100%" }}
      loading={selectLoading}
      onChange={handleOrderStatusChange}
      value={currentStatus}
    >
      <Select.Option value="pending">Pending</Select.Option>
      <Select.Option value="inProgress">In Progress</Select.Option>
      <Select.Option value="done">Done</Select.Option>
      <Select.Option value="cancelled">Cancelled</Select.Option>
    </Select>
  );
};

export default OrderStatusSelect;
