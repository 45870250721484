import { useEffect, useState } from "react";
import "./NotificationsTab.css"; // Custom CSS for dropdown styling
import axios from "axios";
import { onMessageListener } from "../firebase/firebase";

const NotificationsTab = ({ notifications, loading }) => {
  const [open, setOpen] = useState(false); // State to control dropdown visibility
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState({});

  // const fetchNotification = () => {
  //   axios
  //     .get(`${import.meta.env.VITE_APP_BASE_API_URL}//notifications/admin-notifications`, {
  //       headers: {
  //         Authorization: `Bearer ${user?.data?.accessToken}`,
  //       },
  //     })
  //     .then((res) => {
  //       setData(res.data.notifications);
  //     });
  // };

  const renderDescription = (description) => {
    return {
      __html: description || "No Description", // Return description as HTML
    };
  };
  useEffect(() => {
    setData(notifications)
   
    // fetchNotification();
  }, [open])
  useEffect(() => {
    setData(notifications)
    // fetchNotification();
  }, [onMessageListener])

  return (
    <div className="notifications-container">
      <button className="bell-button" onClick={() => setOpen(!open)}>
        {loading ? "Loading..." : "🔔"}
      </button>

      {open && (
        <div className="dropdown">

          {data.length > 0 ? (
            data.map((notification) => (
              <div className="dropdown-item" key={notification.id}>
                <strong>{notification.title?.english || "No Title"}</strong>:{" "}
                <span
                  dangerouslySetInnerHTML={renderDescription(
                    notification.description?.english
                  )}
                />
              </div>
            ))
          ) : (
            <div className="dropdown-item">No notifications available</div>
          )}

        </div>
      )}
    </div>
  );
};

export default NotificationsTab;
