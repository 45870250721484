import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddAmenities from "./Amenities/AddAmenities";
import Swal from "sweetalert2";
import EditAmenities from "./Amenities/EditAmenities";

const Amenities = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL
        }/hotelAmenities/get-amenities?hotel=${selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log("joe",res.data.amenities)
        setData(res.data.amenities);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDeactivate = (item) => {
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelamenities?id=${item}`,
        { active: false },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  const handleActivate = (item) => {
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelamenities?id=${item.id}`,
        { 
          active: true },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["name" , "english"],
    },

    {
      title: "Status",
      dataIndex: "active",
      render: (status, row) =>
        status ? (
          <Button onClick={() => handleDeactivate(row.id)} danger>
            Deactivate
          </Button>
        ) : (
          <Button onClick={() => handleActivate(row)} ghost type="primary">
            Activate
          </Button>
        ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => (
        <div className="row-actions">
          <Button
            style={{ marginRight: 16 }}
            onClick={() => handleViewClick(row)}  // Set modalData on View
          >
            View
          </Button>
          <Button danger onClick={() => handleDeleteClick(row)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];
  
  useEffect(() => {
    fetchData();
  }, [selectedHotel]);

  const handleDeleteClick = (record) => {
    console.log("Delete : ", record.id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${import.meta.env.VITE_APP_BASE_API_URL}/hotelAmenities`, {
            params: {
              id: record.id,
            },
            headers: {
              Authorization: `Bearer ${user.data.accessToken}`,
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "Your offer has been deleted.", "success");
            fetchData(); // Refresh data after successful deletion
          })
          .catch((error) => {
            // Swal.fire("Error!", "There was a problem deleting the offer.", "error");
            console.error("Error deleting offer:", error);
            fetchData();
          });
      }
    });
  };

  const handleViewClick = (row) => {
    console.log("data",data)
    console.log("row",row)
    setViewModalOpen(true);  // Open the modal
    setModalData(row);

  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
        <Button
          type="primary"
          onClick={() => setAddModalOpen(true)}
          style={{ marginBottom: 16 }}
        >
          Add Amenities
        </Button>
      </div>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={data}
      />

      {addModalOpen && (
        <AddAmenities
          refetch={fetchData}
          hotel={selectedHotel.hotelID}
          addModalOpen={addModalOpen}
          setAddModalOpen={() => setAddModalOpen(false)}
          onAddOffer={() => {
            fetchData();
            setAddModalOpen(false);
          }}
        />
      )}

      {viewModalOpen && (  // Check if modalData is set
    
        <EditAmenities
          hotel={selectedHotel.hotelID}
          modalOpen={viewModalOpen}
          setModalOpen={setViewModalOpen}
          data={modalData}  // Pass modalData to EditAmenities
          refetch={fetchData}
        />
        
      )}
    </div>
  );
};

export default Amenities;
