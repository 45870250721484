import { useNavigate } from "react-router-dom";
import axios from "axios";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user?.data?.accessToken) {
      try {
        const url = `${import.meta.env.VITE_APP_BASE_API_URL}/users/fcmDestroy`;
        const headers = {
          Authorization: `Bearer ${user.data.accessToken}`,
        };
        await axios.post(url, {}, { headers });
      } catch (error) {
        console.error("Failed to destroy FCM session during logout:", error);
      }
    }

    // Clear localStorage and navigate to login
    localStorage.removeItem("user");
    localStorage.removeItem("tabs");
    navigate("/login");
  };

  // Trigger the logout on component mount
  handleLogout();

  return null; // No UI is rendered
};

export default Logout;
