import "../assets/scss/DashboardHeader.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import { Avatar, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setHotel, setHotelLoading } from "../store/slices/hotelSlice";
import NotificationsTab from "./NotificationsTab";
import { UserOutlined } from "@ant-design/icons";
import EditProfileModal from "./EditProfileModal";
import { onMessageListener } from "../firebase/firebase";

const DashboardHeader = ({ menuWidth }) => {
  const [hotelData, setHotelData] = useState([]);

  const [editProfileModal, setEditProfileModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [loading, setLoading] = useState(false); // For notifications loading state
  const user = JSON.parse(localStorage.getItem("user"));
  const hotelLoading = useSelector((state) => state.hotelReducer.loadingHotel);
  const hotelID = useSelector((state) => state.hotelReducer.hotel.hotelID);
  const hotelName = useSelector((state) => state.hotelReducer.hotel.hotelName);
  const dispatch = useDispatch();
  const superRoles = [1, 2, 12];




  const fetchHotelsAndServices = async (defaultHotelID = null) => {
    try {
      // Fetch all hotels
      const hotelResponse = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotels/get-hotels`,
        {
          headers: { Authorization: `Bearer ${user?.data?.accessToken}` },
        }
      );
      if (hotelID == null) {
        dispatch(
          setHotel({
            hotelName: user?.data?.userProfile?.userDetails?.hotelDetails?.name?.english,
            hotelID: user?.data?.userProfile?.userDetails?.hotelDetails?.id,
          })
        );
      }
      setHotelData(hotelResponse.data.hotels);

      // Get hotel ID from Redux or user profile
      const initialHotelID =
        defaultHotelID ||
        hotelID ||
        user?.data?.userProfile?.userDetails?.hotelDetails?.id;

      // Find selected hotel
      const selectedHotel = hotelResponse.data.hotels.find(
        (hotel) => hotel.id === initialHotelID
      );

      // Fetch services for the selected hotel
      const servicesResponse = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/services/admin-services?hotel=${selectedHotel.id}`,
        {
          headers: { Authorization: `Bearer ${user?.data?.accessToken}` },
        }
      );

      // Update Redux with the selected hotel and its services
      dispatch(
        setHotel({
          hotelName: selectedHotel?.name?.english,
          hotelID: selectedHotel.id,
          services: servicesResponse.data.services,
        })
      );
    } catch (error) {
      console.error("Error fetching hotels and services:", error);
    } finally {
      // Ensure loading state is false
      dispatch(setHotelLoading(false));
    }
  };


  const fetchUserNotifications = () => {
    setLoading(true);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL
        }/notifications/get-notifications`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        setNotifications(res.data.notifications || []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };



  const handleChange = async (value) => {
    dispatch(setHotelLoading(true));

    const selectedHotel = hotelData.find((hotel) => hotel.id === value);

    if (!selectedHotel) {
      console.error("Selected hotel not found in hotel data");
      dispatch(setHotelLoading(false)); // Ensure loading state is false
      return;
    }


    try {
      const servicesResponse = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/services/admin-services?hotel=${selectedHotel.id}`,
        {
          headers: { Authorization: `Bearer ${user?.data?.accessToken}` },
        }
      );


      dispatch(
        setHotel({
          hotelName: selectedHotel?.name?.english,
          hotelID: selectedHotel.id,
          services: servicesResponse.data.services,
        })
      );

      console.log("Updated Redux state:", store.getState()); // Log Redux state

    } catch (error) {
      console.error("Error changing hotel:", error.response || error.message || error);
    } finally {
      dispatch(setHotelLoading(false)); // Ensure loading state is false
    }
  };



  const fetchAllHotelsAndUserHotel = () => {
    axios
      .get(`${import.meta.env.VITE_APP_BASE_API_URL}/hotels/get-hotels`, {
        headers: {
          Authorization: "Bearer " + user?.data?.accessToken,
        },
      })
      .then(function (response) {
        setHotelData(response.data.hotels);
        axios
          .get(
            `${import.meta.env.VITE_APP_BASE_API_URL
            }/services/admin-services?hotel=${user.data.userProfile.userDetails.hotelDetails.id
            }`,
            {
              headers: {
                Authorization: `Bearer ${user?.data?.accessToken}`,
              },
            }
          )
          .then((res) => {
            dispatch(
              setHotel({
                hotelName:
                  user?.data?.userProfile?.userDetails?.hotelDetails?.name
                    .english,
                hotelID: user?.data?.userProfile?.userDetails?.hotelDetails?.id,
                services: res.data.services,
              })
            );
            dispatch(setHotelLoading(false));
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchSingleHotelServices = () => {
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL
        }/services/admin-services?hotel=${user.data.userProfile.userDetails.hotelDetails.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        dispatch(
          setHotel({
            hotelName:
              user?.data?.userProfile?.userDetails?.hotelDetails?.name.english,
            hotelID: user?.data?.userProfile?.userDetails?.hotelDetails?.id,
            services: res.data.services,
          })
        );
        dispatch(setHotelLoading(false));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchUserHotel = () => {
    if (superRoles.includes(user.roles.id)) {
      // fetchAllHotelsAndUserHotel()
      fetchHotelsAndServices();
    } else {
      fetchSingleHotelServices();
    }
  };

  useEffect(() => {
    const initializeListener = async () => {
      try {
        await onMessageListener();
        setRerender(true);
      } catch (error) {
        console.log("Error with Firebase listener", error);
      }
    };

    initializeListener(); // Initialize listener on component mount
  }, []); // Empty dependency array ensures it runs once on mount


  useEffect(() => {
    fetchUserNotifications();
    fetchUserHotel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?.accessToken]);
  useEffect(() => {
    fetchUserHotel();
  }, [user?.data?.accessToken, hotelID]);


  useEffect(() => {
    if (rerender) {
      fetchUserNotifications();
    }
  }, [rerender]);

  return (
    <header
      className="dashboard-header"
      style={{ padding: "24px 24px 24px", marginLeft: menuWidth }}
    >
      <div
        style={{ display: "flex", gap: 10, alignItems: "center" }}
        className="select-hotel"
      >
        <p>Hotel:</p>
        {user?.roles?.id !== 1 &&
          user?.roles?.id !== 2 &&
          user?.roles?.id !== 12 ? (
          <p>
            {user?.data?.userProfile?.userDetails?.hotelDetails?.name?.english}
          </p>
        ) : hotelData.length > 0 ? (
          <Select
            style={{ width: 250 }}
            disabled={hotelLoading}
            onChange={handleChange}
            value={hotelID}
          >
            {hotelData.map((hotel) => (
              <Select.Option key={hotel.id} value={hotel.id}>
                {hotel?.name?.english}
              </Select.Option>
            ))}
          </Select>
        ) : (
          "Loading Hotels"
        )}
      </div>
      <div className="right">
        <div className="profile">
          <Avatar
            onClick={() => setEditProfileModal(true)}
            icon={
              user.data.userProfile.profilePicture ? (
                <img
                  src={user.data.userProfile.profilePicture}
                  alt="User Picture"
                />
              ) : (
                <UserOutlined />
              )
            }
          />
          <EditProfileModal
            setEditProfileModal={setEditProfileModal}
            editProfileModal={editProfileModal}
          />
        </div>
        <div className="notifications">
          <NotificationsTab notifications={notifications.reverse()} loading={loading} />
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
