import { Route, Routes } from "react-router-dom";
import "./App.css";
import RequireAuth from "./components/RequireAuth";
import Dashboard from "./pages/DashboardTab/Dashboard";
import Unauthorized from "./pages/Unauthorized";
import Login from "./pages/Login";
import AdminsList from "./pages/UsersTabs/AdminsList";
import Logout from "./pages/Logout";
import MarketingAdminsList from "./pages/UsersTabs/MarketingAdminsList";
import OffersListTab from "./pages/OffersListTab/OffersListTab";
import PushNotificationsTab from "./pages/PushNotificationsTab/PushNotificationsTab";
import DashboardLayout from "./layouts/DashboardLayout";
import DashboardNavigator from "./components/DashboardNavigator";
import Outlets from "./pages/OutletsTab/Outlets";
import RoomServiceSupervisorsList from "./pages/UsersTabs/RoomServiceSupervisorsList";
import RoomServiceStaffList from "./pages/UsersTabs/RoomServiceStaffList";
import HousekeepingSupervisorsList from "./pages/UsersTabs/HousekeepingSupervisorsList";
import HousekeepingStaffList from "./pages/UsersTabs/HousekeepingStaffList";
import MaintenanceStaffList from "./pages/UsersTabs/MaintenanceStaffList";
import MaintenanceSupervisorsList from "./pages/UsersTabs/MaintenanceSupervisorsList";
import FrontOfficeList from "./pages/UsersTabs/FrontOfficeList";
import FrontOffice from "./pages/FrontOfficeTab/FrontOffice";
import Excursions from "./pages/ExcursionsTab/Excursions";
import ActivitiesFacilities from "./pages/ActivitiesFacilitiesTab/ActivitiesFacilities";
import Housekeeping from "./pages/HousekeepingTab/Housekeeping";
import Maintenance from "./pages/MaintenanceTab/Maintenance";
import Reviews from "./pages/Reviews";
import GuestList from "./pages/UsersTabs/GuestList";
import Hotels from "./pages/HotelsTab/Hotels";
import RoomServiceCategory from "./pages/RoomServicesTab/RoomServiceCategory";
import RoomServices from "./pages/RoomServicesTab/RoomServices";
import MaintenanceCategory from "./pages/MaintenanceTab/MaintenanceCategory";
import HousekeepingCategory from "./pages/HousekeepingTab/HousekeepingCategory";
import ExcursionsCategory from "./pages/ExcursionsTab/ExcursionsCategory";
import FrontOfficeCategory from "./pages/FrontOfficeTab/FrontOfficeCategory";
import ActivitiesFacilitiesCategory from "./pages/ActivitiesFacilitiesTab/ActivitiesFacilitiesCategory";
import LoyalityTiers from "./pages/LoyalityProgramTab/LoyalityTiers/LoyalityTiers";
import HotelRooms from "./pages/HotelRoomsTab/HotelRooms";
import HotelAreas from "./pages/HotelAreasTab/HotelAreas";
import OrdersController from "./pages/OrdersListTab/OrdersController";
import LoyalityUsers from "./pages/LoyalityProgramTab/LoyalityUsers/LoyalityUsers";
import ViewLoyalityUsers from "./pages/LoyalityProgramTab/LoyalityUsers/ViewLoyalityUsers";
import ITAdmins from "./pages/UsersTabs/ITAdmins";
import Amenities from "./pages/Amenities";
import Reservations from "./pages/Reservations";
import ActionsLog from "./pages/ActionsLog";
import ITStaff from "./pages/UsersTabs/ITStaff";
import RoomTypes from "./pages/RoomTypes/RoomTypes";
import HandleTabClose from "./pages/HandleTabClose";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user data exists in localStorage
    const user = localStorage.getItem("user");
    if (!user) {
      localStorage.removeItem("tabs");
      // If no user data is found, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);
  return (
    <>
      <HandleTabClose />

      <Routes>
        {/*  NOTE:  K ADMIN ROUTES */}

        <Route path="/ka" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[1]} />}>
            <Route path="adminlist" element={<AdminsList />} />
            <Route path="log" element={<ActionsLog />} />
            <Route path="amenities" element={<Amenities />} />
            <Route path="loyaltytiers" element={<LoyalityTiers />} />
            <Route path="loyaltyusers" element={<LoyalityUsers />} />
            <Route path="loyaltyusers/:id" element={<ViewLoyalityUsers />} />
            <Route path="guests" element={<GuestList />} />
            <Route path="hotels" element={<Hotels />} />
            <Route path="hotelrooms" element={<HotelRooms />} />
            <Route path="hotelareas" element={<HotelAreas />} />
            <Route path="itadmins" element={<ITAdmins />} />
            <Route path="itstaff" element={<ITStaff />} />
            <Route path="marketingadmins" element={<MarketingAdminsList />} />
            <Route path="rssuper" element={<RoomServiceSupervisorsList />} />
            <Route path="rsstaff" element={<RoomServiceStaffList />} />
            <Route path="hksuper" element={<HousekeepingSupervisorsList />} />
            <Route path="hkstaff" element={<HousekeepingStaffList />} />
            <Route path="mnsuper" element={<MaintenanceSupervisorsList />} />
            <Route path="mnstaff" element={<MaintenanceStaffList />} />
            <Route path="folist" element={<FrontOfficeList />} />
            <Route path="frontoffice" element={<FrontOffice />} />
            <Route path="frontoffice/:id" element={<FrontOfficeCategory />} />
            <Route path="housekeeping" element={<Housekeeping />} />
            <Route path="housekeeping/:id" element={<HousekeepingCategory />} />
            <Route path="excursions" element={<Excursions />} />
            <Route path="excursions/:id" element={<ExcursionsCategory />} />
            <Route path="facilities" element={<ActivitiesFacilities />} />
            <Route
              path="facilities/:id"
              element={<ActivitiesFacilitiesCategory />}
            />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="maintenance/:id" element={<MaintenanceCategory />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="reservations" element={<Reservations />} />
            <Route path="roomtypes" element={<RoomTypes />} />
            <Route path="offers" element={<OffersListTab />} />
            <Route path="orders" element={<OrdersController />} />
            <Route path="outlet" element={<Outlets />} />
            <Route path="push" element={<PushNotificationsTab />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="roomservice" element={<RoomServices />} />
            <Route path="roomservice/:id" element={<RoomServiceCategory />} />
          </Route>
        </Route>

        {/*  NOTE:  Marketing ADMIN ROUTES */}

        <Route path="/ma" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[2]} />}>
            <Route path="hotels" element={<Hotels />} />
            <Route path="reservations" element={<Reservations />} />
            <Route path="amenities" element={<Amenities />} />
            <Route path="frontoffice" element={<FrontOffice />} />
            <Route path="guests" element={<GuestList />} />
            <Route path="frontoffice/:id" element={<FrontOfficeCategory />} />
            <Route path="excursions" element={<Excursions />} />
            <Route path="excursions/:id" element={<ExcursionsCategory />} />
            <Route path="facilities" element={<ActivitiesFacilities />} />
            <Route
              path="facilities/:id"
              element={<ActivitiesFacilitiesCategory />}
            />
            <Route path="log" element={<ActionsLog />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="loyaltytiers" element={<LoyalityTiers />} />
            <Route path="hotelrooms" element={<HotelRooms />} />
            <Route path="hotelareas" element={<HotelAreas />} />
            <Route path="loyaltyusers" element={<LoyalityUsers />} />
            <Route path="loyaltyusers/:id" element={<ViewLoyalityUsers />} />
            <Route path="roomtypes" element={<RoomTypes />} />
            <Route path="offers" element={<OffersListTab />} />
            <Route path="outlet" element={<Outlets />} />
            <Route path="push" element={<PushNotificationsTab />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="adminlist" element={<AdminsList />} />
            <Route path="marketingadmins" element={<MarketingAdminsList />} />
            <Route path="itadmins" element={<ITAdmins />} />
            <Route path="itstaff" element={<ITStaff />} />
            <Route path="rssuper" element={<RoomServiceSupervisorsList />} />
            <Route path="rsstaff" element={<RoomServiceStaffList />} />
            <Route path="hksuper" element={<HousekeepingSupervisorsList />} />
            <Route path="hkstaff" element={<HousekeepingStaffList />} />
            <Route path="mnsuper" element={<MaintenanceSupervisorsList />} />
            <Route path="mnstaff" element={<MaintenanceStaffList />} />
            <Route path="folist" element={<FrontOfficeList />} />
            <Route path="roomservice" element={<RoomServices />} />
            <Route path="roomservice/:id" element={<RoomServiceCategory />} />
          </Route>
        </Route>

        {/*  NOTE:  IT ADMIN ROUTES */}

        <Route path="/it" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[12]} />}>
            <Route path="adminlist" element={<AdminsList />} />
            <Route path="guests" element={<GuestList />} />
            <Route path="hotels" element={<Hotels />} />
            <Route path="hotelrooms" element={<HotelRooms />} />
            <Route path="hotelareas" element={<HotelAreas />} />
            <Route path="marketingadmins" element={<MarketingAdminsList />} />
            <Route path="itadmins" element={<ITAdmins />} />
            <Route path="itstaff" element={<ITStaff />} />
            <Route path="rssuper" element={<RoomServiceSupervisorsList />} />
            <Route path="rsstaff" element={<RoomServiceStaffList />} />
            <Route path="hksuper" element={<HousekeepingSupervisorsList />} />
            <Route path="hkstaff" element={<HousekeepingStaffList />} />
            <Route path="mnsuper" element={<MaintenanceSupervisorsList />} />
            <Route path="mnstaff" element={<MaintenanceStaffList />} />
            <Route path="folist" element={<FrontOfficeList />} />
            <Route path="frontoffice" element={<FrontOffice />} />
            <Route path="frontoffice/:id" element={<FrontOfficeCategory />} />
            <Route path="housekeeping" element={<Housekeeping />} />
            <Route path="housekeeping/:id" element={<HousekeepingCategory />} />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="maintenance/:id" element={<MaintenanceCategory />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="loyaltytiers" element={<LoyalityTiers />} />
            <Route path="orders" element={<OrdersController />} />
            <Route path="reservations" element={<Reservations />} />
            <Route path="push" element={<PushNotificationsTab />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="roomservice" element={<RoomServices />} />
            <Route path="roomservice/:id" element={<RoomServiceCategory />} />
            <Route path="log" element={<ActionsLog />} />
          </Route>
        </Route>

        {/*  NOTE:  IT STAFF ROUTES */}

        <Route path="/its" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[14]} />}>
            <Route path="adminlist" element={<AdminsList />} />
            <Route path="hotels" element={<Hotels />} />
            <Route path="hotelrooms" element={<HotelRooms />} />
            <Route path="hotelareas" element={<HotelAreas />} />
            <Route path="marketingadmins" element={<MarketingAdminsList />} />
            <Route path="itadmins" element={<ITAdmins />} />
            <Route path="itstaff" element={<ITStaff />} />
            <Route path="rssuper" element={<RoomServiceSupervisorsList />} />
            <Route path="rsstaff" element={<RoomServiceStaffList />} />
            <Route path="hksuper" element={<HousekeepingSupervisorsList />} />
            <Route path="hkstaff" element={<HousekeepingStaffList />} />
            <Route path="mnsuper" element={<MaintenanceSupervisorsList />} />
            <Route path="mnstaff" element={<MaintenanceStaffList />} />
            <Route path="folist" element={<FrontOfficeList />} />
            <Route path="frontoffice" element={<FrontOffice />} />
            <Route path="frontoffice/:id" element={<FrontOfficeCategory />} />
            <Route path="housekeeping" element={<Housekeeping />} />
            <Route path="housekeeping/:id" element={<HousekeepingCategory />} />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="maintenance/:id" element={<MaintenanceCategory />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="loyaltytiers" element={<LoyalityTiers />} />
            <Route path="orders" element={<OrdersController />} />
            <Route path="reservations" element={<Reservations />} />
            <Route path="push" element={<PushNotificationsTab />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="roomservice" element={<RoomServices />} />
            <Route path="roomservice/:id" element={<RoomServiceCategory />} />
            <Route path="log" element={<ActionsLog />} />
          </Route>
        </Route>

        {/*  NOTE:  ADMIN Routes */}

        <Route path="/a" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[3]} />}>
            <Route path="adminlist" element={<AdminsList />} />
            <Route path="guests" element={<GuestList />} />
            <Route path="hotels" element={<Hotels />} />
            <Route path="rssuper" element={<RoomServiceSupervisorsList />} />
            <Route path="rsstaff" element={<RoomServiceStaffList />} />
            <Route path="hksuper" element={<HousekeepingSupervisorsList />} />
            <Route path="hkstaff" element={<HousekeepingStaffList />} />
            <Route path="mnsuper" element={<MaintenanceSupervisorsList />} />
            <Route path="mnstaff" element={<MaintenanceStaffList />} />
            <Route path="folist" element={<FrontOfficeList />} />
            <Route path="frontoffice" element={<FrontOffice />} />
            <Route path="frontoffice/:id" element={<FrontOfficeCategory />} />
            <Route path="housekeeping" element={<Housekeeping />} />
            <Route path="housekeeping/:id" element={<HousekeepingCategory />} />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="maintenance/:id" element={<MaintenanceCategory />} />
            <Route path="reservations" element={<Reservations />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="orders" element={<OrdersController />} />
            <Route path="loyaltytiers" element={<LoyalityTiers />} />
            <Route path="push" element={<PushNotificationsTab />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="roomservice" element={<RoomServices />} />
            <Route path="roomservice/:id" element={<RoomServiceCategory />} />
          </Route>
        </Route>

        {/*  NOTE:  Front Office Routes */}

        <Route path="/fo" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[4]} />}>
            <Route path="frontoffice" element={<FrontOffice />} />
            <Route path="frontoffice/:id" element={<FrontOfficeCategory />} />
            <Route path="orders" element={<OrdersController />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="reservations" element={<Reservations />} />
          </Route>
        </Route>

        {/*  NOTE:  Maintenance Supervisor Routes */}

        <Route path="/msv" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[5]} />}>
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="maintenance/:id" element={<MaintenanceCategory />} />
            <Route path="mnsuper" element={<MaintenanceSupervisorsList />} />
            <Route path="mnstaff" element={<MaintenanceStaffList />} />
            <Route path="orders" element={<OrdersController />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>

        {/*  NOTE:  Maintenance staff Routes */}

        <Route path="/ms" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[6]} />}>
            <Route path="orders" element={<OrdersController />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>

        {/*  NOTE:  HK Supervisor Routes */}

        <Route path="/hksv" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[7]} />}>
            <Route path="housekeeping" element={<Housekeeping />} />
            <Route path="housekeeping/:id" element={<HousekeepingCategory />} />
            <Route path="hksuper" element={<HousekeepingSupervisorsList />} />
            <Route path="hkstaff" element={<HousekeepingStaffList />} />
            <Route path="orders" element={<OrdersController />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>

        {/*  NOTE:  HK staff Routes */}

        <Route path="/hks" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[8]} />}>
            <Route path="orders" element={<OrdersController />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>

        {/*  NOTE:  RS Supervisor Routes */}

        <Route path="/rssv" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[9]} />}>
            <Route path="orders" element={<OrdersController />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="rssuper" element={<RoomServiceSupervisorsList />} />
            <Route path="rsstaff" element={<RoomServiceStaffList />} />
            <Route path="roomservice" element={<RoomServices />} />
            <Route path="roomservice/:id" element={<RoomServiceCategory />} />
          </Route>
        </Route>

        {/*  NOTE:  RS Staff Routes */}

        <Route path="/rss" element={<DashboardLayout />}>
          <Route element={<RequireAuth allowedRoles={[10]} />}>
            <Route path="orders" element={<OrdersController />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Route>

        {/*  NOTE:  LOGOUT if logged in ROUTE */}

        <Route
          element={
            <RequireAuth allowedRoles={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14]} />
          }
        >
          <Route element={<Logout />} path="/logout" />
        </Route>

        {/*  NOTE:  PUBLIC ROUTES */}

        <Route path="/" element={<DashboardNavigator />} />
        <Route path="/unauthorized" replace={true} element={<Unauthorized />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
