import React, { useEffect, useState } from "react";

const CustomSearchSelect = ({ options, value, onSearch, onChange, placeholder }) => {
  const [searchInput, setSearchInput] = useState(value);
  const [filteredData, setFilteredData] = useState(options);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSearch = async (search) => {
    if (search.length >= 3) {
      setLoading(true); // Show loading spinner
      try {
        // Your API call can be added here (this is just a placeholder)
        const response = await fetch(
          `${import.meta.env.VITE_APP_BASE_API_URL}/users/get-users-V2?email=${search}`
        );
        const data = await response.json();
        const newOptions = data.users.map((user) => ({
          value: user.id,
          label: user.email,
        }));
        setFilteredData(newOptions); // Set users options for dropdown
        onSearch(newOptions); // Pass the new options to the parent component
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false); // Hide spinner
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    onChange(value); // Update parent component's state
  };

  const handleSelect = (item) => {
    setSelectedItems((prevSelected) => [...prevSelected, item]);
    setSearchInput(""); // Clear input field after selection
    setFilteredData([]); // Close the dropdown after selecting an item
    onChange([...selectedItems, item]); // Send selected items to parent
  };

  const handleRemove = (item) => {
    setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    onChange(selectedItems.filter((selectedItem) => selectedItem !== item)); // Update parent with new selected items
  };

  useEffect(() => {
    if (typeof value === "string" && value.length >= 3) {
      setLoading(true);
      setFilteredData(
        options.filter((option) =>
          option.label.toLowerCase().includes(value.toLowerCase())
        )
      );
      setLoading(false);
    } else if (Array.isArray(value)) {
      // If value is an array, you might want to handle it differently or ignore this block.
      setFilteredData([]);
    } else {
      setFilteredData([]);
    }
  }, [value, options]);


  return (
    <div>
      <input
        type="text"
        value={searchInput}
        onChange={handleInputChange}
        placeholder={placeholder}
        style={{
          width: "96%",
          padding: "8px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          outline: "none"
        }}
      />
      <div
        style={{
          marginTop: "10px",
          maxHeight: "200px",
          overflowY: "auto",
          borderRadius: "4px",
        }}
      >
        {filteredData.length > 0 &&
          filteredData.map((item) => (
            <div
              key={item.value}
              onClick={() => handleSelect(item)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderBottom: "1px solid #f1f1f1",
              }}
            >
              {item.label}
            </div>
          ))}
      </div>

      {/* Selected items section */}
      {selectedItems.length > 0 && (
        <div style={{ marginTop: "15px" }}>
          <strong>Selected:</strong>
          <div style={{ marginTop: "5px", display: "flex", flexWrap: "wrap" }}>
            {selectedItems.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px",
                  margin: "5px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  borderRadius: "4px",
                }}
              >
                {item.label}
                <span
                  onClick={() => handleRemove(item)}
                  style={{
                    marginLeft: "8px",
                    cursor: "pointer",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSearchSelect;
