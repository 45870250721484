import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddServiceItem from "../../components/AddServiceItemModal";
import Swal from "sweetalert2";
import ViewItem from "../../components/ViewItem";
import ChangeItemStatusButton from "../../components/ChangeItemStatusButton";
import ActivitiesFacilitiesEditItem from "./ActivitiesFacilitiesEditItem";
import ActivitiesFacilitiesCreateItem from "./ActivitiesFacilitiesCreateItem";
import DOMPurify from 'dompurify';

// render description html
const renderHTML = (htmlString) => {
  const cleanHTML = DOMPurify.sanitize(htmlString);
  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};


const ActivitiesFacilitiesCategory = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [addItemModal, setAddItemModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();



  useEffect(() => {
    fetchData();
  }, []);

  // const fetchData = () => {
  //   setLoading(true);
  //   axios
  //     .get(
  //       `${import.meta.env.VITE_APP_BASE_API_URL
  //       }/items/get-items?serviceCategory=${params.id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user.data.accessToken}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       const openingHours = res.data.items[0]?.extraFields?.openingHours ;
  //       const phone = res.data.items[0]?.extraFields?.phone ;
  //       const extraFields = res.data.items[0]?.extraFields ;
  //       console.log("extraFields:", extraFields);
  //       console.log("openingHours:", openingHours);
  //       console.log("phone:", phone);

  //       setData(res.data.items);
  //       setLoading(false);
  //     });
  // };

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items/get-items-v2?serviceCategory=${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log("API Response:", res.data); // Log the whole response

        const items = res.data.items.map(item => {
          let extraFields = item.extraFields;

          // Check if extraFields is a string and parse it
          if (typeof extraFields === "string") {
            try {
              extraFields = JSON.parse(extraFields); // Convert string to object
            } catch (error) {
              console.error("Failed to parse extraFields:", error);
              extraFields = {}; // Default to an empty object if parsing fails
            }
          }

          // Combine the necessary fields into a new object
          return {
            ...item,
            openingHours: extraFields?.openingHours, // Provide default values
            phone: extraFields?.phone ,
          };
        });
        setData(items); // Set the processed items to state
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Ensure loading state is reset
      });
  };




  const handleDeleteClick = (record, rowIndex) => {
    console.log(rowIndex);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d375c",
      confirmButtonText: "Yes, delete it!",
      preConfirm: () => {
        return axios
          .delete(
            `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${record.id}`,
            {
              headers: {
                Authorization: `Bearer ${user.data.accessToken}`,
              },
            }
          )
          .then(() => {
            // setIsDeleting((prev) => !prev);
            fetchData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["name", "english"],
      key: "name",
      width: '15%',
    },
    {
      title: "Description",
      dataIndex: ["description", "english"],
      key: "desc",
      ellipsis: true,
      width: '30%',
      render: (htmlContent) => renderHTML(htmlContent),
    },
    {
      title: "Phone",
      dataIndex: "phone", // Update this to directly access phone
      key: "phone",
    },
    {
      title: "Opening Hours",
      dataIndex: "openingHours", // Update this to directly access openingHours
      key: "openingHours",
    },
    {
      title: "Actions",
      key: "actions",
      width: '30%',
      render: (record) =>
        [1, 2, 3].includes(user?.roles?.id) ? (
          <>
            <ActivitiesFacilitiesEditItem item={record} refetch={fetchData} />
            <Button
              danger
              dashed="true"
              onClick={() => handleDeleteClick(record)}
            >
              Delete
            </Button>
            <ChangeItemStatusButton item={record} refetch={fetchData} />
          </>
        ) : (
          ""
        ),
    },
  ];
  

  return (
    <div>
      {[1, 2, 3].includes(user?.roles?.id) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
          className="top-controls"
        >
          <Button
            onClick={() => setAddItemModal(true)}
            type="primary"
            style={{ marginRight: 16, textAlign: "right" }}
          >
            Add Item
          </Button>
        </div>
      ) : (
        ""
      )}
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={data}
      />

      <ActivitiesFacilitiesCreateItem
        addItemModal={addItemModal}
        setAddItemModal={setAddItemModal}
        clickedCategory={params}
        refetch={fetchData}
      />
    </div>
  );
};

export default ActivitiesFacilitiesCategory;
