import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "../../assets/scss/Charts.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const LoyalityTiersChart = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [chartData, setChartData] = useState([]);

  const fetchChart = () => {
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/statistics/redeemedEarnedPoints`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        let temp = [
          {
            name: "Bronze",
            Earned: res.data.bronzeTier.earnedPoints,
            Redeemed: res.data.bronzeTier.redeemedPoints,
            Total: res.data.bronzeTier.users,
          },
          {
            name: "Silver",
            Earned: res.data.silverTier.earnedPoints,
            Redeemed: res.data.silverTier.redeemedPoints,
            Total: res.data.silverTier.users,
          },
          {
            name: "Gold",
            Earned: res.data.goldTier.earnedPoints,
            Redeemed: res.data.goldTier.redeemedPoints,
            Total: res.data.goldTier.users,
          },
          {
            name: "Platinum",
            Earned: res.data.platinumTier.earnedPoints,
            Redeemed: res.data.platinumTier.redeemedPoints,
            Total: res.data.platinumTier.users,
          },
        ];
        setChartData(temp);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchChart(); 
  }, []);
  return (
    <div className="LoyalityTiersChart single-chart">
      <h2>Loyalty Tiers</h2>
      <BarChart width={500} height={300} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Total" fill="#cb9e6b" />
        <Bar dataKey="Earned" fill="#113b5f" />
        <Bar dataKey="Redeemed" fill="#246194" />
      </BarChart>
    </div>
  );
};

export default LoyalityTiersChart;
